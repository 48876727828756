import { fetchNotifications } from "../services/blt";
import {
    selectAllNotifications,
    selectNotificationById,
    selectSeenNotifications,
    selectVisibleNotifications,
    selectUnseenNotificationsByLocation,
} from "../selectors";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";

export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const SET_SEEN_NOTIFICATIONS = "SET_SEEN_NOTIFICATIONS";
export const SET_VISIBLE_NOTIFICATIONS = "SET_VISIBLE_NOTIFICATIONS";

export const SHOW_LOW_SERVICE_BANNER = "SHOW_LOW_SERVICE_BANNER";
export const HIDE_LOW_SERVICE_BANNER = "HIDE_LOW_SERVICE_BANNER";

// simple action creators
export const showToast = ({ type, disableAutoHide }) => ({
    type: SHOW_TOAST,
    payload: { type, show: true, disableAutoHide },
});

export const dismissToast = () => ({
    type: HIDE_TOAST,
    payload: { show: false },
});

export const setNotifications = (notifications) => ({
    type: SET_NOTIFICATIONS,
    payload: notifications,
});

export const setSeenNotifications = (seenNotifications) => ({
    type: SET_SEEN_NOTIFICATIONS,
    payload: seenNotifications,
});

export const showLowServiceBanner = () => ({
    type: SHOW_LOW_SERVICE_BANNER,
});

export const hideLowServiceBanner = () => ({
    type: HIDE_LOW_SERVICE_BANNER,
});

export const setVisibleNotifications = (setVisibleNotifications) => ({
    type: SET_VISIBLE_NOTIFICATIONS,
    payload: setVisibleNotifications,
});

// async/complex action creators
export const getNotifications = (params, headers) => async (dispatch) => {
    const notifications = await fetchNotifications(params, headers);

    dispatch(setNotifications(notifications));

    return notifications;
};

export const updateSeenNotifications =
    (allVisibleNotifications) => (dispatch, getState) => {
        const currentSeenNotifications = selectSeenNotifications(getState());
        const seenNotifications = []
            .concat(
                allVisibleNotifications || [],
                currentSeenNotifications || []
            )
            .filter((id, i, arr) => arr.indexOf(id) === i);

        return dispatch(setSeenNotifications(seenNotifications));
    };

export const updateAndRecordSeenNotifications =
    (newSeenNotifications) => (dispatch, getState) => {
        const currentSeenNotifications = selectSeenNotifications(getState());
        const seenNotifications = []
            .concat(newSeenNotifications || [], currentSeenNotifications || [])
            .filter((id, i, arr) => arr.indexOf(id) === i);

        const permanentSeenNotifications = seenNotifications
            .map((id) => selectNotificationById(getState(), id))
            .filter((ele) => ele && !ele.prominenceEmphasizedAttribute)
            .map(({ id }) => id);

        permanentSeenNotifications.length &&
            localStorage.setItem(
                "seenNotifications",
                JSON.stringify(permanentSeenNotifications)
            );

        return dispatch(setSeenNotifications(seenNotifications));
    };

export const updateVisibleNotifications =
    (location) => (dispatch, getState) => {
        const state = getState();
        const visibleNotifications = selectUnseenNotificationsByLocation(
            state,
            location
        );

        return dispatch(setVisibleNotifications(visibleNotifications));
    };

export const setInitialVisibleNotifications =
    (location) => (dispatch, getState) => {
        const state = getState();
        const curentlyVisibleNotifications = selectVisibleNotifications(state);
        const unseenNotifications = selectUnseenNotificationsByLocation(
            state,
            location
        );
        const visibleNotifications = [
            ...new Set([
                ...curentlyVisibleNotifications,
                ...unseenNotifications,
            ]),
        ];

        return dispatch(setVisibleNotifications(visibleNotifications));
    };

export const triggerNotification = (locatorId) => (dispatch, getState) => {
    const state = getState();
    const notification = selectAllNotifications(state).filter((notification) =>
        notification.locatorIds.includes(locatorId)
    )[0];
    const visibleNotifications = selectVisibleNotifications(state);

    return dispatch(
        setVisibleNotifications([notification, ...visibleNotifications])
    );
};
