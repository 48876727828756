import { createGlobalStyle } from "styled-components";
import "./base.css";
import "./type.css";
import {
    accentColorConfirmation,
    accentColorDanger,
    accentColorInfo,
    accentColorLink,
    accentColorLinkActive,
    accentColorLinkVisited,
    accentColorPrimary,
    accentColorWarning,
    backgroundColorActive,
    backgroundColorAlt,
    backgroundColorDisabled,
    backgroundColorHover,
    backgroundColorLevel1,
    backgroundColorLevel2,
    backgroundColorLevel3,
    backgroundColorLevel4,
    backgroundColorLevel5,
    backgroundColorLevelCurrent,
    black,
    blue25,
    blue30,
    borderColorPrimary,
    borderColorSecondary,
    borderColorTertiary,
    boxShadowDetached,
    boxShadowOverlaid,
    boxShadowPoppedOut,
    boxShadowRaised,
    breakWidth960,
    fontSize11,
    fontSize13,
    fontSize14,
    fontSize16,
    fontSize18,
    fontSize20,
    fontSize28,
    fontSize32,
    fontSize42,
    fontWeightBold,
    fontWeightLight,
    fontWeightNormal,
    fontWeightSemiBold,
    getAppearanceConstant,
    grey2,
    grey3,
    grey5,
    grey10,
    grey15,
    grey20,
    grey30,
    grey40,
    highlightBlue,
    highlightBrown,
    highlightDarkBlue,
    highlightGreen,
    highlightGrey,
    highlightOrange,
    highlightPink,
    highlightPurple,
    highlightRed,
    highlightYellow,
    lineHeight,
    lineHeightLoose,
    lineHeightTight,
    monospace,
    red10,
    red30,
    sans,
    serif,
    spacing4,
    spacing8,
    spacing16,
    spacing24,
    spacing32,
    spacing64,
    spacing128,
    text120,
    textColorInverted,
    textColorPrimary,
    textColorSecondary,
    textColorTertiary,
    white,
    whiteTransparency60,
    zIndexHigherThanPlatform,
} from "@churchofjesuschrist/eden-style-constants";

const highlightColors = {
    blue: highlightBlue,
    brown: highlightBrown,
    dark_blue: highlightDarkBlue,
    gray: highlightGrey,
    green: highlightGreen,
    orange: highlightOrange,
    pink: highlightPink,
    purple: highlightPurple,
    red: highlightRed,
    yellow: highlightYellow,
    clear: backgroundColorLevelCurrent,
};

/**
 * Unity doesn't have underline highlight colors defined and the normal highlight colors are too light to be used as underlines. The below removes the alpha hex from the provided highlight colors which makes them stronger versions of the same and more suitable for underlines. This isn't officially blessed so we may need to re-visit this at some point.
 */
let underlineLightCode = [];
let underlineDarkCode = [];
for (const [key, value] of Object.entries(highlightColors)) {
    // Get the unity values for the highlight version
    const curValues = getAppearanceConstant(value);

    underlineLightCode.push(
        `--${key}Underline: ${curValues.light.match(/#[a-fA-F0-9]{6}/)?.[0]};`
    );
    underlineDarkCode.push(
        `--${key}Underline: ${curValues.dark.match(/#[a-fA-F0-9]{6}/)?.[0]};`
    );
}

function dashToCamelCase(str) {
    return str.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
}
const getConstantsFromList = (list, theme = "dark") => {
    return list
        .map((color) => {
            const curValues = getAppearanceConstant(color);
            // have to output both the camelCase and the dash-case for the CSS variables to handle eden, local and IPS css blocks. Hope this doesn't carry over to next.
            return `${curValues.name}: ${curValues[theme]}; --${dashToCamelCase(
                curValues.name.replace("--", "")
            )}: ${curValues[theme]};`;
        })
        .join("\n");
};

const themeRelatedConstants = [
    accentColorConfirmation,
    accentColorDanger,
    accentColorInfo,
    accentColorLink,
    accentColorLinkActive,
    accentColorLinkVisited,
    accentColorPrimary,
    accentColorWarning,
    backgroundColorActive,
    backgroundColorAlt,
    backgroundColorDisabled,
    backgroundColorHover,
    backgroundColorLevel1,
    backgroundColorLevel2,
    backgroundColorLevel3,
    backgroundColorLevel4,
    backgroundColorLevel5,
    backgroundColorLevelCurrent,
    boxShadowDetached,
    boxShadowOverlaid,
    boxShadowPoppedOut,
    boxShadowRaised,
    textColorPrimary,
    textColorSecondary,
    textColorTertiary,
    textColorInverted,
    borderColorPrimary,
    borderColorSecondary,
    borderColorTertiary,
    highlightBlue,
    highlightBrown,
    highlightDarkBlue,
    highlightGreen,
    highlightGrey,
    highlightOrange,
    highlightPink,
    highlightPurple,
    highlightRed,
    highlightYellow,
];

/* Eden doesn't output light versions of their CSS variables (we aren't technically supposed to use them directly anyway) as they are meant to be imported in react components.  We use a lot of .css files that can't import those so we need to define the light versions of these variables. The following imports the appearance constants and re-sets them as local variables we can use using the name of the export as the variable name.  They output in the format of `var(--accent-color-confirmation, #64A53D)`. The `--accent-color-confirmation` is set for the dark mode, but not light mode, and the fallback color is used for light mode when the dark is not set. This allows us to use `--accentColorConfirmation` in .css files and `accentColorConfirmation` in react files leaving the `--accent-color-confirmation` to be set by eden based on dark mode usage.  */
const GlobalStyles = createGlobalStyle`
body {
  --black:${black};
  --blue25:${blue25};
  --blue30:${blue30};
  --breakWidth960:${breakWidth960};
  --fontSize11:${fontSize11};
  --fontSize13:${fontSize13};
  --fontSize14:${fontSize14};
  --fontSize16:${fontSize16};
  --fontSize18:${fontSize18};
  --fontSize20:${fontSize20};
  --fontSize28:${fontSize28};
  --fontSize32:${fontSize32};
  --fontSize42:${fontSize42};
  --fontWeightBold:${fontWeightBold};
  --fontWeightLight:${fontWeightLight};
  --fontWeightNormal:${fontWeightNormal};
  --fontWeightSemiBold:${fontWeightSemiBold};
  --grey2:${grey2};
  --grey3:${grey3};
  --grey5:${grey5};
  --grey10:${grey10};
  --grey15:${grey15};
  --grey20:${grey20};
  --grey30:${grey30};
  --grey40:${grey40};
  --lineHeight:${lineHeight};
  --lineHeightLoose:${lineHeightLoose};
  --lineHeightTight:${lineHeightTight};
  --monospace:${monospace};
  --red10:${red10};
  --red30:${red30};
  --sans:${sans};
  --serif:${serif};
  --spacing128:${spacing128};
  --spacing16:${spacing16};
  --spacing24:${spacing24};
  --spacing32:${spacing32};
  --spacing4:${spacing4};
  --spacing64:${spacing64};
  --spacing8:${spacing8};
  --text120:${text120};
  --white:${white};
  --whiteTransparency60:${whiteTransparency60};
  --zIndexHigherThanPlatform:${zIndexHigherThanPlatform};
  --panelBackground: var(--grey2);
  --panelBorders: var(--borderColorTertiary);
}
  ${
      ""
      /** The `getConstantsFromList` below are to get colors in place that will apply ASAP when the page loads, while waiting for JS to load and apply the correct full theme */
  }

  body, .theme-light {
    ${underlineLightCode.join("\n")}
    --panelBackground: var(--grey2);
    ${getConstantsFromList(themeRelatedConstants, "light")}
}

@media (prefers-color-scheme: dark) {
    body {
        ${underlineDarkCode.join("\n")}
        --panelBackground: var(--background-color-level2);
        ${getConstantsFromList(themeRelatedConstants, "dark")}
    }
}
.theme-dark {
    ${underlineDarkCode.join("\n")}
    --panelBackground: var(--background-color-level2);
    ${getConstantsFromList(themeRelatedConstants, "dark")}
}
`;

const NormalizeCss = ({ children }) => (
    <>
        <GlobalStyles />
        {children}
    </>
);

export default NormalizeCss;
