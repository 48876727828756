import { fetchContent, fetchDynamic, fetchToc } from "../services/blt";
import { addTableOfContents, addReaderContent } from "./reader";
import {
    selectContent,
    selectLibrary,
    selectLoggedIn,
    selectPlainLibrary,
    selectToc,
    selectPlainToc,
} from "../selectors";
import { addLibraryData } from "./library";
import { setHttpResponseCode } from "./system";
import { toReducerKey } from "../../util/reducer-helpers";

const determineContentUri = (contentUri, book) =>
    book.navList.find((uri) => contentUri.includes(uri.split("#")[0]))
        ? contentUri
        : book.firstContentUri;

// protect against grabbing data we already have
const buildFetchData =
    (book, content, library, state) => async (params, headers) => ({
        content:
            book?.firstContentUri && !content
                ? await fetchContent(
                      {
                          ...params,
                          uri: determineContentUri(
                              params.uri,
                              selectToc(
                                  state,
                                  toReducerKey(book.uri, params.lang)
                              )
                          ),
                      },
                      headers
                  )
                : content,
        toc:
            content?.tableOfContentsUri && !book
                ? await fetchToc(
                      { ...params, uri: content.tableOfContentsUri },
                      headers
                  )
                : book,
        ...(!book && !content && !library
            ? await fetchDynamic(params, headers)
            : { collection: library }),
    });

export const getDynamicData =
    (params, headers) => async (dispatch, getState) => {
        const state = getState();
        let loggedIn = selectLoggedIn(state);
        let { lang, uri } = params;

        uri = uri.split(".")[0];

        let actions = [];
        let status = 200;

        let libraryKey = toReducerKey(uri, lang);
        let library = selectLibrary(state, libraryKey);

        let contentKey = toReducerKey(uri, lang);
        let content = selectContent(state, contentKey);

        let bookKey = toReducerKey(content.tableOfContentsUri || uri, lang);
        let book = selectToc(state, bookKey);

        if (!content.content && book.entries) {
            contentKey = toReducerKey(determineContentUri(uri, book), lang);
            content = selectContent(state, contentKey);
        }

        // reset any invalid local values
        library = library.loaded && selectPlainLibrary(state, libraryKey);
        content = content.content && content;
        book = book.entries && selectPlainToc(state, bookKey);

        let verified = library?.verified ?? content?.verified ?? book?.verified;

        // force refetch for unverified data
        if (loggedIn && verified === false) {
            library = false;
            content = false;
            book = false;

            params.includeSecureContent = true;
        }

        if (!library && !(content && book)) {
            const fetchData = buildFetchData(book, content, library, state);

            try {
                let dynamicData = await fetchData(params, headers);
                ({ collection: library, toc: book, content } = dynamicData);
            } catch (err) {
                status = err.status;
                library = content = book = err;
            }

            if (library) {
                actions.push(addLibraryData(library, lang));
            }

            if (content) {
                actions.push(addTableOfContents(book, lang));
                actions.push(addReaderContent(content, lang));

                if (content.content && uri !== content.uri) {
                    actions.push(
                        addReaderContent(
                            {
                                uri,
                                status: 404,
                                redirect: toReducerKey(content.uri, lang),
                            },
                            lang
                        )
                    );
                }
            }
        }

        actions.push(setHttpResponseCode(status));

        actions.forEach((action) => dispatch(action));

        let newState = getState();

        return {
            book: selectToc(newState, bookKey),
            content: selectContent(newState, contentKey),
            library: selectLibrary(newState, libraryKey),
        };
    };
