import { fetchCoreContentFragmentsAsPost } from "../services/coreContent";
import { selectCoreContentFragments } from "../selectors/index";

export const ADD_CORE_CONTENT_FRAGMENTS = "ADD_CORE_CONTENT_FRAGMENTS";

export const addCoreContentFragments = (fragments = {}) => ({
    type: ADD_CORE_CONTENT_FRAGMENTS,
    payload: fragments,
});

export const getCoreContentFragments =
    (params, headers) => (dispatch, getState) => {
        const coreContentFragments = selectCoreContentFragments(getState());
        // determine which URIs are missing
        const { missingFragments, exisitingFragments } = params.uris.reduce(
            ({ missingFragments, exisitingFragments }, uri) => {
                if (coreContentFragments[uri]) {
                    exisitingFragments[uri] = coreContentFragments[uri];
                } else {
                    missingFragments.push(uri);
                }

                return { missingFragments, exisitingFragments };
            },
            { missingFragments: [], exisitingFragments: {} }
        );

        // get missing URI's content
        if (missingFragments.length) {
            return fetchCoreContentFragmentsAsPost(params, headers).then(
                (fragments) => {
                    // Combine results and return
                    const allFragments = {
                        ...exisitingFragments,
                        ...fragments,
                    };
                    dispatch(addCoreContentFragments(fragments));

                    return allFragments;
                }
            );
        }

        return Promise.resolve(exisitingFragments);
    };

export const getCoreContentFragmentsPassthrough =
    (returnData, params, headers) => (dispatch, getState) =>
        getCoreContentFragments(params, headers)(dispatch, getState).then(() =>
            Promise.resolve(returnData)
        );
