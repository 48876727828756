import {
    ADD_FLOATING_PANEL,
    CLEAR_FLOATING_PANELS,
    REMOVE_FLOATING_PANEL,
    REMOVE_FLOATING_PANEL_BY_ID,
    SET_PANEL,
    SET_SUB_PANEL,
} from "../actions/sidePanel";
import { LOCATION_CHANGE } from "../actions/system";
import { currentReducerKey, reduceEntries } from "../../util/reducer-helpers";

export default (state = {}, { type, payload = {} }) => {
    const currentKey = state.currentKey;

    switch (type) {
        case SET_PANEL:
            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    activePanel: payload,
                    activeSubPanel: null,
                    floatingPanels: [],
                    focusOnMount: true,
                },
            };

        case SET_SUB_PANEL:
            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    activeSubPanel: payload,
                    focusOnMount: true,
                },
            };

        case ADD_FLOATING_PANEL:
            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    floatingPanels: [
                        ...(state[currentKey]?.floatingPanels || []),
                        payload,
                    ],
                    focusOnMount: true,
                },
            };

        case REMOVE_FLOATING_PANEL:
            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    floatingPanels:
                        state[currentKey]?.floatingPanels.slice(0, -1) || [],
                },
            };

        case REMOVE_FLOATING_PANEL_BY_ID:
            if (
                !state[currentKey]?.floatingPanels?.some(
                    (panel) => panel.id === payload
                )
            )
                return state;

            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    floatingPanels: state[currentKey].floatingPanels.filter(
                        (panel) => panel.id !== payload
                    ),
                },
            };

        case CLEAR_FLOATING_PANELS:
            return {
                ...state,
                [currentKey]: {
                    ...state[currentKey],
                    floatingPanels: [],
                },
            };

        case LOCATION_CHANGE: {
            const newKey = currentReducerKey(payload);
            const adjustedState = Object.entries(state)
                .map(([key, value]) => [key, { ...value, focusOnMount: false }])
                .reduce(reduceEntries, {});

            const previousPanels = adjustedState[state.currentKey] || {};

            adjustedState.currentKey = newKey;
            adjustedState[newKey] = {
                ...previousPanels,
                activePanel: previousPanels.activePanel
                    ? previousPanels.activePanel
                    : previousPanels.floatingPanels?.length
                    ? "RelatedContentPanel"
                    : null,
                floatingPanels: adjustedState[newKey]?.floatingPanels || [],
            };

            return adjustedState;
        }

        default:
            return state;
    }
};
