import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "../../util/utils";
import LibraryContainer from "./library";
import ReaderContainer from "./reader";
import NotFoundContainer from "./not-found";
import {
    selectContent,
    selectCSSRules,
    selectLang,
    selectLibrary,
    selectLoggedIn,
    selectVisibleNotifications,
    selectToc,
    selectDynamicStatusCode,
} from "../selectors";

import { getDynamicData } from "../actions/dynamic";
import { getI18nData } from "../actions/i18n";
import {
    getNotifications,
    updateVisibleNotifications,
    setInitialVisibleNotifications,
    setVisibleNotifications,
} from "../actions/notifications";
import { setTocCollectionOverride } from "../actions/reader";
import { addStyleSheet, styleSheetLoaded } from "../actions/styles";
import { getCSSRules } from "../actions/system";
import { cleanUri, createLocation } from "../../util/uri-utils";
import {
    useForceUpdate,
    useUpdateEffect,
    useTheme,
} from "../../util/custom-hooks";
import GeneralContext from "../components/GeneralContext";
import { useLocation, useHistory } from "react-router-dom";

const getRedirectData = (
    content = {},
    library = { sections: [] },
    location
) => {
    return library.sections.length
        ? [library.uri, "library"]
        : content.content
        ? [content.uri, "reader"]
        : [location.pathname];
};

// Captures everything dot notation, query/search and hash from the url.
const URL_MODIFIERS_REGEX = /^[^/]*?\/.+?([.?#][^/]*$)/;

function fixUri(content, library, location, history) {
    let [uri] = getRedirectData(content, library, location);
    let cleanCurrentUri = cleanUri(location.pathname);
    let context = location.pathname.split(".")[1];
    let uriWithContext = context ? `${uri}.${context}` : uri;

    if (
        !(
            location.pathname === uriWithContext &&
            location.pathname === cleanCurrentUri
        )
    ) {
        let redirectUri = `${uri}${(location.href || "").replace(
            URL_MODIFIERS_REGEX,
            "$1"
        )}`;
        history.replace(createLocation(redirectUri));
    }
}

const Dynamic = (props) => {
    const cssRules = useSelector(selectCSSRules);
    const dispatch = useDispatch();
    const forceUpdate = useForceUpdate();
    const fromLibrary = useRef(false);
    const verifyData = useRef(false);
    const history = useHistory();
    const { lang } = useContext(GeneralContext);
    const [loadedLocation, setLoadedLocation] = useState();
    const loading = useRef(false);
    const location = useLocation();
    const loggedIn = useSelector(selectLoggedIn);
    const notifications = useSelector(selectVisibleNotifications);
    const status = useSelector((state) =>
        selectDynamicStatusCode(state, location)
    );

    let content = useSelector((state) => selectContent(state, location));
    let library = useSelector((state) => selectLibrary(state, location));
    let loadedContent = useSelector((state) =>
        selectContent(state, loadedLocation || location)
    );
    let loadedLibrary = useSelector((state) =>
        selectLibrary(state, loadedLocation || location)
    );

    useEffect(() => {
        if (content.content && !loadedLibrary.isDefault) {
            dispatch(setTocCollectionOverride(content, loadedLibrary, lang));
        }
    }, [content, loadedLibrary, lang, dispatch]);

    const allProps = {
        ...props,
        i18n: useSelector((state) => state.i18n),
        loading: loading.current,
        tableOfContents: useSelector((state) => selectToc(state, location)),
        content,
        library,
        location: loadedLocation,
        notifications,
    };

    const updateData = async () => {
        let { i18n, tableOfContents } = allProps;

        let needsLibraryData = !(
            library.loaded &&
            (library.verified || !loggedIn)
        );
        let needsReaderData = !(
            tableOfContents.entries &&
            content.content &&
            ((tableOfContents.verified && content.verified) || !loggedIn)
        );

        let needsData =
            [200, 401].includes(status) && needsLibraryData && needsReaderData;

        if (needsData && !loading.current) {
            let uri = location.pathname.split(".")[0];
            loading.current = true;

            isEmpty(cssRules) && dispatch(getCSSRules({}));
            isEmpty(i18n[lang]) && dispatch(getI18nData({ lang }));
            notifications === undefined && dispatch(getNotifications({ lang }));
            dispatch(updateVisibleNotifications(location));

            ({ library, content } = await dispatch(
                getDynamicData({ uri, lang })
            ));

            loading.current = false;

            verifyData.current =
                !(library.verified && content.verified) && loggedIn;

            fixUri(content, library, location, history);
            setLoadedLocation(location);
            forceUpdate();
        } else if (!needsData) {
            setLoadedLocation(location);
        }
    };
    // TODO: Disable below is temporary to have as little functionality change as possible in this PR
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => void fixUri(content, library, location, history), []);

    useUpdateEffect(() => {
        if (
            loadedContent.tableOfContentsUri &&
            content.tableOfContentsUri !== loadedContent.tableOfContentsUri
        ) {
            dispatch(setVisibleNotifications([]));
        }
    }, [content.tableOfContentsUri, loadedContent.tableOfContentsUri]);

    useEffect(() => {
        void updateData();
        verifyData.current = false;
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, loggedIn, verifyData.current]);

    useEffect(() => {
        if (!loading.current) {
            document.dispatchEvent(new Event("DOMContentLoaded"));
            document.dispatchEvent(new CustomEvent("checkcontext"));
            document.activeElement && document.activeElement.blur();
        }
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading.current, location.pathname]);

    useEffect(() => {
        dispatch(getCSSRules({}));
        dispatch(getNotifications({ lang })).then(() =>
            dispatch(setInitialVisibleNotifications(location))
        );
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useTheme();

    const allowClientSideRetry =
        !loadedLocation &&
        (loadedContent.status === 401 || loadedLibrary.status === 401);

    let Component = loadedContent.content
        ? ReaderContainer
        : loadedLibrary?.sections?.length || allowClientSideRetry
        ? LibraryContainer
        : NotFoundContainer;

    // readerView will update this to false when it navigates internally
    if (Component === LibraryContainer) {
        fromLibrary.current = true;
    }

    return <Component {...allProps} fromLibrary={fromLibrary} />;
};

// Gets called by _server.js for server-side rendering
Dynamic.updateInitialStore = async (store, uri) => {
    let state = store.getState(),
        context = state.preview ? "preview" : undefined,
        dispatch = store.dispatch,
        headers = { cookie: state.headers.cookie },
        lang = selectLang(state);

    let actions = [
        getCSSRules({}, headers),
        getDynamicData({ lang, uri, context }, headers),
    ];

    // eslint-disable no-unused-vars
    let [, { content }] = await Promise.all(actions.map(dispatch));
    let links = content?.content?.head?.links || [];

    links.forEach((link) => {
        dispatch(addStyleSheet(link));
        dispatch(styleSheetLoaded(link));
    });

    return {
        context,
        dispatch,
        headers,
        lang,
        uri,
    };
};

export default Dynamic;
