import "isomorphic-fetch";
import { get } from "@churchofjesuschrist/universal-env";
import { v4 as uuidv4 } from "uuid";
import { getBltUrl } from "../../../util/blt-utils.js";
import { addHeaders } from "../../../util/fetch-utils";
import {
    addPrefix,
    paramsToSearch,
    prependAppBase,
    removeAppBase,
    removeVerse,
} from "../../../util/uri-utils.js";
import { checkBlacklistUrlChars } from "./blacklistUrlChars.js";

const { APP_PATHNAME } = get();

export const fetchData = (api, params, headers) => {
    let bltUrl = getBltUrl(api, { source: "language-pages" }),
        uri = params.uri,
        init = {
            method: "GET",
            mode: "cors",
            compress: true,
            credentials: "include",
            headers: {
                correlationToken: uuidv4(),
                accept: "application/json",
            },
        };

    init = addHeaders(init, headers);

    if (uri) {
        params.uri = removeAppBase(params.uri);
        params.uri = removeVerse(params.uri);
    }

    if (checkBlacklistUrlChars(paramsToSearch(params))) {
        const status = 404;
        const message = "Not Found";

        return Promise.reject({
            uri: prependAppBase(params.uri),
            status,
            message,
        });
    }

    return fetch(`${bltUrl}${paramsToSearch(params)}`, init)
        .then((response) =>
            response.ok ? response.json() : Promise.reject(response)
        )
        .then((json) => {
            addPrefix(json, "uri", APP_PATHNAME);

            return Promise.resolve(json);
        })
        .catch(async (error = {}) => {
            const { status, statusText: message } = error;

            if (status === 401) {
                const { verified } = await error.json();

                return Promise.reject({
                    uri: prependAppBase(params.uri),
                    status,
                    message,
                    verified,
                });
            }

            return Promise.reject({
                uri: prependAppBase(params.uri),
                status,
                message,
            });
        });
};
