export const addToLocalStorage = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = (key) => {
    return localStorage.getItem(key);
};

export const deleteFromLocalStorage = (key) => {
    return localStorage.removeItem(key);
};

export const addToSessionStorage = (key, value) => {
    return sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSessionStorage = (key) => {
    return sessionStorage.getItem(key);
};

export const deleteFromSessionStorage = (key) => {
    return sessionStorage.removeItem(key);
};
