import { createObjectSelector } from "reselect-map";
import { isEmpty, pickBy } from "../../util/utils";
import {
    selectContent,
    buildDynamicContentKey,
    selectCoreContentFragments,
    selectToc,
} from "./selectors";
import mem from "mem";
import { reduceEntries } from "../../util/reducer-helpers";
import { createSelector } from "../../util/reselect";

const NO_HIGHLIGHTS = [];

export const selectDocId = createSelector(
    [selectContent],
    (content) => content?.meta?.pageAttributes?.["data-aid"] || ""
);

export const selectContentVersion = createSelector(
    [selectContent],
    (content) => content?.meta?.pageAttributes?.["data-aid-version"] || ""
);

export const selectContentTitle = createSelector(
    [selectContent],
    (content) => content?.meta?.title || ""
);

export const selectPublicationTitle = createSelector(
    [selectToc],
    (toc) => toc.title
);

export const selectActiveAnnotationId = (state) =>
    state.annotations.activeAnnotation;

const _selectAnnotations = (state) => state.annotations.annotations;

export const selectActiveAnnotation = (state) => {
    let activeAnnotationId = selectActiveAnnotationId(state);

    return _selectAnnotations(state)[activeAnnotationId];
};

const _selectHighlights = (state) => state.annotations.highlights;

const _selectRefs = (state) => state.annotations.refs;

export const selectRefById = (state, id) => _selectRefs(state)[id];

const _selectBookmarks = (state) => state.annotations.bookmarks;

export const selectFolders = (state) => state.annotations.folders;

export const selectFolderById = (state, id) => selectFolders(state)[id];

export const selectTags = (state) => state.annotations.tags;

export const selectTagById = (state, id) => selectTags(state)[id];

export const selectTagNameById = (state, id) => selectTagById(state, id)?.name;

export const _selectSets = (state) => state.annotations.sets;

export const selectSets = createSelector([_selectSets], (sets) => {
    const setEntries = sets.map((set) => [set.id, set]);

    return new Map(setEntries);
});

const _selectSetOrder = (state) => state.annotations.setOrder;

export const selectSortedSets = createSelector(
    [_selectSetOrder, selectSets],
    (setOrder, sets) => setOrder.map((setId) => sets.get(setId))
);

export const selectSetById = (state, id) => selectSets(state).get(id);

export const selectActiveSet = (state) => {
    const activeSetId = selectActiveSetId(state);

    return selectSetById(state, activeSetId);
};

export const selectActiveSetId = (state) => state.annotations.activeSetId;

export const selectAnnotationsInitialized = createSelector(
    [
        (state) => state.annotations.initialized,
        (state, locationOrId) => buildDynamicContentKey(locationOrId),
    ],
    (initializedUrls, contentKey) => initializedUrls[contentKey]
);

const _selectAnnotationsByActiveSet = createSelector(
    [_selectAnnotations, selectActiveSetId],
    (allAnnotations, activeSetId) => {
        // TODO: when we support viewing multiple sets, this will need to be updated
        const annotations = Object.entries(allAnnotations)
            .filter(([, annotation]) => activeSetId === annotation.setId)
            .reduce(reduceEntries, {});

        return annotations;
    }
);

const selectAnnotations = createObjectSelector(
    [_selectAnnotations, selectFolders, selectTags],
    (annotation, folders, tags) => {
        return {
            ...annotation,
            folders: !isEmpty(folders)
                ? annotation.folders.map((folderId) => folders[folderId])
                : [],
            tags: !isEmpty(tags)
                ? annotation.tags.map((tagId) => tags[tagId])
                : [],
        };
    }
);

const selectAnnotationsByActiveSet = createObjectSelector(
    [_selectAnnotationsByActiveSet, selectFolders, selectTags],
    (annotation, folders, tags) => {
        return {
            ...annotation,
            folders: !isEmpty(folders)
                ? annotation.folders.map((folderId) => folders[folderId])
                : [],
            tags: !isEmpty(tags)
                ? annotation.tags.map((tagId) => tags[tagId])
                : [],
        };
    }
);

const HIGHLIGHT_TYPES = ["highlight", "reference"];

const _selectHighlightAnnotations = createSelector(
    [selectAnnotationsByActiveSet],
    (annotations) =>
        pickBy(annotations, ({ type }) => HIGHLIGHT_TYPES.includes(type))
);

export const selectHighlights = createObjectSelector(
    [_selectHighlights],
    (highlight) => pickBy(highlight)
);

const buildKeyBuilder = () => {
    let counts = {};

    return (key) => {
        counts[key] = (counts[key] || 0) + 1;

        return `${key}_${counts[key]}`;
    };
};

export const selectRefs = createObjectSelector(
    [_selectRefs, selectCoreContentFragments],
    (ref, coreContentFragments) => ({
        reference: coreContentFragments[`/${ref.lang}${ref.uri}`],
        ...ref,
    })
);

export const selectHighlightAnnotations = createObjectSelector(
    [
        _selectHighlightAnnotations,
        selectHighlights,
        selectRefs,
        selectCoreContentFragments,
    ],
    (annotation, highlightFragments, globalRefs, coreContentFragments) => {
        const buildRefKey = buildKeyBuilder();
        const { lang, highlightUri } = annotation;
        const coreContentFragment =
            coreContentFragments[`/${lang}${highlightUri}`] || {};
        const highlights = annotation.highlights.map(
            (key) => highlightFragments[key]
        );
        const refs = annotation.refs.map((key) => ({
            ...globalRefs[key],
            key: buildRefKey(key),
        }));

        return {
            ...annotation,
            highlights,
            icon: highlights[0].icon,
            marker: coreContentFragment?.content?.[0]?.displayId || "",
            reference: coreContentFragment,
            refs,
        };
    }
);

const memSelectHighlightAnnotationById = mem(
    (annotations) => (id) => annotations[id] || null
);

export const selectHighlightAnnotationById = (state, id) => {
    const highlightAnnotations = selectHighlightAnnotations(state);
    const _selectAnnotationById =
        memSelectHighlightAnnotationById(highlightAnnotations);

    return id ? _selectAnnotationById(id) : _selectAnnotationById;
};

export const selectActiveHighlightAnnotation = (state) => {
    const annotationId = selectActiveAnnotationId(state);

    return annotationId
        ? selectHighlightAnnotationById(state, annotationId)
        : null;
};

export const selectHighlightAnnotationsByDocId = createSelector(
    [selectHighlightAnnotations, selectDocId],
    (annotations, docId) =>
        pickBy(annotations, (annotation) => annotation.docId === docId)
);

export const selectHighlightsByDocId = createSelector(
    [selectHighlightAnnotationsByDocId],
    (annotations) =>
        Object.values(annotations).reduce(
            (highlights, annotation) =>
                highlights.concat(annotation.highlights),
            NO_HIGHLIGHTS
        )
);

const _selectBookmarkOrder = createSelector(
    [(state) => state.annotations.bookmarkOrder],
    (bookmarkOrder) => bookmarkOrder.map(({ annotationId }) => annotationId)
);

const buildBookmarkContext = ({ name, publication, reference }) =>
    name === reference ? publication : reference;

export const selectBookmarks = createSelector([_selectBookmarks], (bookmarks) =>
    Object.entries(bookmarks)
        .map(([key, bookmark]) => [
            key,
            { ...bookmark, context: buildBookmarkContext(bookmark) },
        ])
        .reduce(reduceEntries, {})
);

const memSelectBookmarkById = mem((bookmarks) => (id) => bookmarks[id] || null);

export const selectBookmarkById = (state, id) => {
    const bookmarks = selectBookmarks(state);
    const _selectBookmarkById = memSelectBookmarkById(bookmarks);

    return id ? _selectBookmarkById(id) : _selectBookmarkById;
};

export const selectSortedBookmarks = createSelector(
    [_selectBookmarkOrder, selectBookmarks],
    (bookmarkOrder, bookmarks) => bookmarkOrder.map((key) => bookmarks[key])
);

export const selectActiveBookmark = (state) => {
    const annotationId = selectActiveAnnotationId(state);

    return annotationId ? selectBookmarkById(state, annotationId) : null;
};

const memSelectBookmarksByPid = mem(
    (bookmarks) => (pid) =>
        Object.values(bookmarks).filter((bookmark) => bookmark.pid === pid)
);

export const selectBookmarksByPid = (state, pid) => {
    const bookmarks = selectBookmarks(state);
    const _selectBookmarksByPid = memSelectBookmarksByPid(bookmarks);

    return pid ? _selectBookmarksByPid(pid) : _selectBookmarksByPid;
};

const _selectBookmarkAnnotations = createSelector(
    [selectAnnotations],
    (annotations) => pickBy(annotations, ({ type }) => type === "bookmark")
);

export const selectBookmarkAnnotations = createObjectSelector(
    [_selectBookmarkAnnotations, selectBookmarks],
    (annotation, bookmarks) => ({
        ...annotation,
        bookmark: bookmarks[annotation.id],
    })
);

export const selectBookmarkAnnotationById = (state, id) => {
    const bookmarkAnnotations = selectBookmarkAnnotations(state);

    return bookmarkAnnotations[id];
};

export const selectActiveAnnotationRefs = createSelector(
    [selectActiveHighlightAnnotation, selectRefs],

    (activeAnnotation, refs) => {
        const { refs: annotationRefs = [] } = activeAnnotation || {};

        return annotationRefs.map((annotationRef) => refs[annotationRef.pid]);
    }
);
