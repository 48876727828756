/* eslint-disable react/display-name */
import { createContext, useContext, useMemo } from "react";
import { dirFromLang } from "../../../util/lang-utils";

let GeneralContext = createContext({
    lang: "eng",
    dir: "ltr",
    crossLinkMode: false,
});

// for class components - use useContext in functional components
export const withGeneralContext = (Component) => (props) => {
    const contextProps = useContext(GeneralContext);

    return <Component {...props} {...contextProps} />;
};

export const GeneralContextProvider = ({
    lang,
    crossLinkMode,
    locationState,
    children,
}) => {
    const defaultContext = useContext(GeneralContext);
    const context = useMemo(
        () => ({
            ...defaultContext,
            lang,
            dir: dirFromLang(lang),
            crossLinkMode,
            locationState,
        }),
        [crossLinkMode, defaultContext, lang, locationState]
    );

    return (
        <GeneralContext.Provider value={context}>
            {children}
        </GeneralContext.Provider>
    );
};

export default GeneralContext;
