import { determineIconType, toReducerKey } from "../../util/reducer-helpers";
import { removeAppBase } from "../../util/uri-utils";
import { isEmpty } from "../../util/utils";
import * as annotationServices from "../services/annotations";
import * as transforms from "../transforms/annotations";
import {
    selectActiveAnnotationId,
    selectActiveAnnotation,
    selectActiveSet,
    selectActiveSetId,
    selectBookmarkById,
    selectContent,
    selectContentTitle,
    selectContentVersion,
    selectDocId,
    selectFolderById,
    selectFolders,
    selectHighlightAnnotationById,
    selectI18nStringById as selectI18nStringByIdBuilder,
    selectLang,
    selectPids,
    selectPublicationTitle,
    _selectSets,
    selectSetById,
    selectTagById,
    selectTags,
    selectTagNameById,
} from "../selectors";
import { showToast } from "./notifications";
import { getCoreContentFragments } from "./coreContent";
import { v4 as uuidv4 } from "uuid";

import { get } from "@churchofjesuschrist/universal-env";
import { updateDefaultHighlightStyle } from "./localSettings";
import {
    handleSaveError,
    handleGetError,
    checkForUnsavedAnnotations,
} from "./handleSaveError";
import analytics from "../../util/analytics";

const handleFireStudyToolsEvent = (type, action) =>
    analytics.fireStudyToolsEvent({
        component: {
            info: {
                name: type,
            },
            category: {
                type: action,
            },
        },
    });

const { APP_URL, STUDY_NOTEBOOK_URL } = get();

export const ADD_FOLDER = "ADD_FOLDER";
export const ADD_TAG = "ADD_TAG";
export const DELETE_ANNOTATION = "DELETE_ANNOTATION";
export const DELETE_ANNOTATIONS = "DELETE_ANNOTATIONS";
export const UPDATE_ANNOTATION = "UPDATE_ANNOTATION";
export const UPDATE_ANNOTATION_SET = "UPDATE_ANNOTATION_SET";
export const UPDATE_NOTE = "UPDATE_NOTE";
export const REMOVE_FOLDER = "REMOVE_FOLDER";
export const REMOVE_TAG = "REMOVE_TAG";

export const CHANGE_ACTIVE_ANNOTATION = "CHANGE_ACTIVE_ANNOTATION";
export const INITIALIZE_ANNOTATIONS = "INITIALIZE_ANNOTATIONS";

export const ADD_BOOKMARKS = "ADD_BOOKMARKS";
export const REORDER_BOOKMARKS = "REORDER_BOOKMARKS";
export const UPDATE_BOOKMARK_LOCATION = "UPDATE_BOOKMARK_LOCATION";
export const UPDATE_BOOKMARK_NAME = "UPDATE_BOOKMARK_NAME";

export const ADD_HIGHLIGHTS = "ADD_HIGHLIGHTS";
export const ADD_REF = "ADD_REF";
export const ADD_REF_TO_ANNOTATION = "ADD_REF_TO_ANNOTATION";
export const REMOVE_REF = "REMOVE_REF";
export const REMOVE_REF_FROM_ANNOTATION = "REMOVE_REF_FROM_ANNOTATION";
export const UPDATE_HIGHLIGHT_OFFSETS = "UPDATE_HIGHLIGHT_OFFSETS";
export const UPDATE_HIGHLIGHT_STYLE = "UPDATE_HIGHLIGHT_STYLE";
export const UPDATE_HIGHLIGHT_ICON = "UPDATE_HIGHLIGHT_ICON";
export const UPDATE_REF = "UPDATE_REF";

export const DELETE_FOLDERS = "DELETE_FOLDERS";
export const REPLACE_FOLDERS = "REPLACE_FOLDERS";

export const REPLACE_TAGS = "REPLACE_TAGS";

export const DELETE_SET = "DELETE_SET";
export const REPLACE_SETS = "REPLACE_SETS";
export const UPDATE_SET = "UPDATE_SET";
export const REORDER_SETS = "REORDER_SETS";
export const CHANGE_ACTIVE_SET = "CHANGE_ACTIVE_SET";

//#region simple actions
// annotations

export const _addFolder = (annotationId, folder) => ({
    type: ADD_FOLDER,
    payload: { annotationId, folder },
});

export const _addTag = (annotationId, tag) => ({
    type: ADD_TAG,
    payload: { annotationId, tag },
});

export const _deleteAnnotation = (annotationId) => ({
    type: DELETE_ANNOTATION,
    payload: annotationId,
});

export const _deleteAnnotations = (annotationIds) => ({
    type: DELETE_ANNOTATIONS,
    payload: annotationIds,
});

export const _deleteFolders = (folderIds) => ({
    type: DELETE_FOLDERS,
    payload: folderIds,
});

export const _removeFolder = (annotationId, folderId) => ({
    type: REMOVE_FOLDER,
    payload: { annotationId, folderId },
});

export const _removeTag = (annotationId, tagId) => ({
    type: REMOVE_TAG,
    payload: { annotationId, tagId },
});

export const _updateAnnotation = (annotation) => ({
    type: UPDATE_ANNOTATION,
    payload: annotation,
});

export const _updateAnnotationSet = (annotationId, setId) => ({
    type: UPDATE_ANNOTATION_SET,
    payload: { annotationId, setId },
});

export const _updateNote = (annotationId, note) => ({
    type: UPDATE_NOTE,
    payload: { annotationId, note },
});

export const changeActiveAnnotation = (annotationId) => ({
    type: CHANGE_ACTIVE_ANNOTATION,
    payload: annotationId,
});

export const initializeAnnotations = (contentKey) => ({
    type: INITIALIZE_ANNOTATIONS,
    payload: { [contentKey]: true },
});

// highlights
export const buildContentUri = (offsets = [{}]) => {
    let [firstUri, lastUri] = [offsets[0].uri, offsets.slice(-1)[0].uri];

    return firstUri !== lastUri && lastUri
        ? `${firstUri}-${lastUri.split(".")[1]}`
        : firstUri;
};

export const _addHighlight = (annotation) => ({
    type: ADD_HIGHLIGHTS,
    payload: [annotation],
});

export const _addHighlights = (annotations) => ({
    type: ADD_HIGHLIGHTS,
    payload: annotations,
});

export const _updateHighlightOffsets = (annotationId, offsets = [], uri) => ({
    type: UPDATE_HIGHLIGHT_OFFSETS,
    payload: { annotationId, offsets, uri },
});

export const _updateHighlightStyle = (
    annotationId,
    { color, underline, clear }
) => ({
    type: UPDATE_HIGHLIGHT_STYLE,
    payload: { annotationId, style: { color, underline, clear } },
});

export const _updateHighlightIcon = (id, icon) => ({
    type: UPDATE_HIGHLIGHT_ICON,
    payload: { id, icon },
});

// refs/crossLinks
export const _addRef = (annotationId, ref) => ({
    type: ADD_REF,
    payload: { annotationId, ref },
});

export const _removeRef = (annotationId, refId) => ({
    type: REMOVE_REF,
    payload: { annotationId, refId },
});

export const _updateRef = (refId, ref) => ({
    type: UPDATE_REF,
    payload: { refId, ref },
});

// bookmarks
export const _addBookmark = (annotation) => ({
    type: ADD_BOOKMARKS,
    payload: [annotation],
});

export const _addBookmarks = (annotations) => ({
    type: ADD_BOOKMARKS,
    payload: annotations,
});

export const _reorderBookmarks = (bookmarkOrder) => ({
    type: REORDER_BOOKMARKS,
    payload: bookmarkOrder,
});

export const _updateBookmarkName = (annotationId, name) => ({
    type: UPDATE_BOOKMARK_NAME,
    payload: { annotationId, name },
});

export const _updateBookmarkLocation = (annotationId, bookmarkLocation) => ({
    type: UPDATE_BOOKMARK_LOCATION,
    payload: { annotationId, ...bookmarkLocation },
});

// tags
export const _replaceTags = (tags) => ({
    type: REPLACE_TAGS,
    payload: tags,
});

// folders
export const _replaceFolders = (folders) => ({
    type: REPLACE_FOLDERS,
    payload: folders,
});

// sets
export const _replaceSets = (sets) => ({
    type: REPLACE_SETS,
    payload: sets,
});

export const _updateSet = (set) => ({
    type: UPDATE_SET,
    payload: set,
});

export const _deleteSet = (setId) => ({
    type: DELETE_SET,
    payload: setId,
});

export const _reorderSets = (sortMap) => ({
    type: REORDER_SETS,
    payload: sortMap,
});

export const _changeActiveSet = (setId) => ({
    type: CHANGE_ACTIVE_SET,
    payload: setId,
});

//#endregion simple actions

//#region async actions
export const addFolder =
    (folder, annotationId) => async (dispatch, getState) => {
        let state = getState();
        let annotation = annotationId
            ? selectHighlightAnnotationById(state, annotationId)
            : selectActiveAnnotation(state);

        if (
            annotation.id &&
            (!annotation.folders.includes(folder.id) || !folder.id)
        ) {
            folder = await dispatch(createFolder(folder));

            dispatch(_addFolder(annotation.id, folder));
            dispatch(updateHighlightIcon(annotation.id));

            annotationServices
                .addFolder(annotation.id, folder.id)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotation.id))
                );
        }
    };

export const addTag = (tag, annotationId) => async (dispatch, getState) => {
    let state = getState();
    let annotation = annotationId
        ? selectHighlightAnnotationById(state, annotationId)
        : selectActiveAnnotation(state);

    if (annotation.id && (!annotation.tags.includes(tag.id) || !tag.id)) {
        tag = await dispatch(createTag(tag));

        dispatch(_addTag(annotation.id, tag.id));
        dispatch(updateHighlightIcon(annotation.id));
        handleFireStudyToolsEvent("tag", "create");

        annotationServices
            .addTag(annotation.id, tag.name)
            .catch((error) => dispatch(handleSaveError(error, annotation.id)));
    }
};

export const deleteAnnotation =
    (annotationId) => async (dispatch, getState) => {
        let state = getState();
        annotationId = annotationId || selectActiveAnnotationId(state);

        selectBookmarkById(state, annotationId)
            ? handleFireStudyToolsEvent("bookmark", "delete")
            : handleFireStudyToolsEvent("mark", "delete");

        if (annotationId) {
            dispatch(changeActiveAnnotation(null));
            dispatch(_deleteAnnotation(annotationId));

            annotationServices.deleteAnnotation(annotationId);
        }
    };

export const removeFolder =
    (folderId, annotationId) => async (dispatch, getState) => {
        let state = getState();
        annotationId = annotationId || selectActiveAnnotationId(state);

        if (annotationId) {
            dispatch(_removeFolder(annotationId, folderId));
            dispatch(updateHighlightIcon(annotationId));

            await annotationServices
                .removeFolder(annotationId, folderId)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const removeTag =
    (tagId, annotationId) => async (dispatch, getState) => {
        let state = getState();
        annotationId = annotationId || selectActiveAnnotationId(state);
        let tagName = selectTagNameById(state, tagId);

        if (annotationId) {
            dispatch(_removeTag(annotationId, tagId));
            dispatch(updateHighlightIcon(annotationId));
            handleFireStudyToolsEvent("tag", "delete");

            await annotationServices
                .removeTag(annotationId, tagName)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const updateAnnotation = (annotation) => async (dispatch) => {
    if (!isEmpty(annotation.refs) && annotation.type !== "reference") {
        annotation.type = "reference";
    }

    annotation = transforms.annotationToApi(annotation);

    annotation = await annotationServices.updateAnnotation(annotation);
    dispatch(_updateAnnotation(annotation));

    let actionCreator =
        annotation.type !== "bookmark" ? _getHighlights : _getBookmarks;

    dispatch(actionCreator([annotation]));
};

export const updateAnnotationSet =
    (annotationId, setId) => async (dispatch) => {
        dispatch(_updateAnnotationSet(annotationId, setId));
        handleFireStudyToolsEvent("study set", "update");

        annotationServices
            .updateAnnotationSet(annotationId, setId)
            .then(() => dispatch(showToast({ type: "annotation-moved" })))
            .catch((error) => dispatch(handleSaveError(error, annotationId)));
    };

export const updateNote =
    (note, annotationId) => async (dispatch, getState) => {
        let state = getState();
        annotationId = annotationId || selectActiveAnnotationId(state);

        if (annotationId) {
            dispatch(_updateNote(annotationId, note));
            dispatch(updateHighlightIcon(annotationId));

            await annotationServices
                .updateNote(annotationId, note)
                .then(() =>
                    dispatch(checkForUnsavedAnnotations("", annotationId))
                )
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const addHighlight =
    (
        {
            folders = [],
            note = {},
            offsets = [], // grab from store?
            refs = [],
            style,
            tags = [],
        },
        location,
        persistStyle = true
    ) =>
    async (dispatch, getState) => {
        const state = getState();
        const highlightUri = buildContentUri(offsets);
        const lang = selectLang(state);
        const newStyle = { ...state.localSettings.defaultStyle, ...style };
        const setId = selectActiveSetId(state);
        const contentVersion = selectContentVersion(state, location);

        let annotation = {
            id: uuidv4(),
            contentVersion,
            docId: selectDocId(state, location),
            folders,
            highlights: offsets,
            highlightUri,
            lang,
            lastUpdated: new Date().toISOString(),
            note,
            refs,
            setId,
            source: APP_URL,
            tags,
            type: refs.length ? "reference" : "highlight",
        };

        dispatch(_addHighlight(annotation));
        dispatch(_updateHighlightStyle(annotation.id, newStyle));
        persistStyle && dispatch(updateDefaultHighlightStyle(newStyle));
        dispatch(
            getCoreContentFragments({
                uris: [toReducerKey(highlightUri, lang)],
            })
        );
        handleFireStudyToolsEvent("mark", "create");

        annotation = selectHighlightAnnotationById(getState(), annotation.id);
        annotationServices
            .addAnnotation(transforms.annotationToApi(annotation))
            .catch((error) => dispatch(handleSaveError(error, annotation.id)));

        return annotation.id;
    };

export const _getHighlights = (annotations) => async (dispatch, getState) => {
    let state = getState();
    let lang = selectLang(state);

    let uris = [];
    let annotationHighlights = annotations.map((annotation) => {
        const highlightUri =
            annotation.highlights && buildContentUri(annotation.highlights);

        uris.push(toReducerKey(highlightUri, lang));
        annotation.highlightUri = highlightUri;

        if (annotation.refs) {
            uris = uris.concat(
                annotation.refs.map((ref) => toReducerKey(ref.uri, ref.locale))
            );
        }

        return transforms.highlightAnnotationFromApi(annotation);
    });

    dispatch(getCoreContentFragments({ uris }));
    dispatch(_addHighlights(annotationHighlights));
};

// Used to see whether getHighlights should be tried again, to avoid infinite looping.
// We try one more time after finding a failure. If that second try fails, then we give up
// until it works again.
let tryAgainOnError = true;

export const getHighlights =
    (uri, types = ["highlight", "reference"]) =>
    async (dispatch, getState) => {
        uri = removeAppBase(uri).split(".")[0];
        let state = getState();
        let lang = selectLang(state);
        let docId = selectDocId(state, toReducerKey(uri, lang));
        let setId = selectActiveSetId(state);
        let contentVersion = selectContentVersion(
            state,
            toReducerKey(uri, lang)
        );

        setId = setId || undefined;

        // Only want to fetch annotation for specfic docs, we never need all annotations at once.
        docId &&
            (await annotationServices
                .getAnnotations({ docId, types, lang, setId, contentVersion })
                .then(async (annotations) => {
                    tryAgainOnError = true;
                    await dispatch(_getHighlights(annotations));
                    dispatch(initializeAnnotations(toReducerKey(uri, lang)));
                })
                .catch((error) => {
                    if (!tryAgainOnError) {
                        return;
                    }

                    tryAgainOnError = false;
                    dispatch(handleGetError(error, getHighlights(uri, types)));
                }));
    };

export const updateHighlightOffsets =
    (offsets) => async (dispatch, getState) => {
        const state = getState();
        const annotationId = selectActiveAnnotationId(state);
        const lang = selectLang(state);

        let uri = buildContentUri(offsets);

        if (annotationId) {
            dispatch(_updateHighlightOffsets(annotationId, offsets, uri));
            dispatch(
                getCoreContentFragments({ uris: [toReducerKey(uri, lang)] })
            );
            handleFireStudyToolsEvent("mark", "update");

            annotationServices
                .updateHighlightOffsets(annotationId, offsets)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const updateHighlightStyle =
    (style, persistStyle = true) =>
    async (dispatch, getState) => {
        const state = getState();
        const annotationId = selectActiveAnnotationId(state);

        if (annotationId) {
            dispatch(_updateHighlightStyle(annotationId, style));
            persistStyle && dispatch(updateDefaultHighlightStyle(style));
            handleFireStudyToolsEvent("mark", "update");

            annotationServices
                .updateHighlightStyle(
                    annotationId,
                    transforms.cleanHighlight(style)
                )
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const updateHighlightIcon = () => async (dispatch, getState) => {
    let state = getState();
    let annotationId = selectActiveAnnotationId(state);
    let annotation = state.annotations.annotations[annotationId];

    annotationId &&
        dispatch(
            _updateHighlightIcon(
                annotation.highlights[0],
                determineIconType(annotation)
            )
        );
};

// Not associated with CrossLinks or refs.
const buildReference = (title, pid) => {
    let verseEl = document.querySelector(
        `[data-aid="${pid}"].verse .verse-number`
    );

    return `${title}${verseEl ? `:${verseEl.innerText.trim()}` : ""}`;
};

const buildVerseRanges = (location, pids, state) => {
    const {
        content: { body },
    } = selectContent(state, location);
    const template = document.createElement("template");
    template.innerHTML = body;

    return Array.from(template.content.querySelectorAll(".verse[data-aid][id]"))
        .filter((ele) => pids.includes(ele.dataset.aid))
        .map((ele) => parseInt(ele.id.replace("p", "")))
        .reduce((acc, verseNum, i, allVerses) => {
            if (verseNum - 1 === allVerses[i - 1]) {
                // is this part of a range?
                acc[acc.length - 1].push(verseNum);
            } else {
                acc.push([verseNum]);
            }

            return acc;
        }, [])
        .map((range) =>
            range.length > 1
                ? `${range[0]}-${range[range.length - 1]}`
                : range[0]
        )
        .join(",");
};

const buildName = (location, state, verseRanges) => {
    const title = selectContentTitle(state, location);

    return verseRanges ? `${title}:${verseRanges}` : title;
};

export const sortPidsByDocumentOrder = (pids, allPids) => {
    pids = [...pids];

    if (pids.length > 0) {
        pids = allPids.reduce((acc, curr) => {
            // put in document order.
            if (pids.some((pid) => pid === curr[0])) {
                acc.push(curr[0]);
            }

            return acc;
        }, []);
    } else {
        pids = [allPids[1][0]]; // select the first pid (usually the title)
    }

    return pids;
};

const doesRefExist = (annotationRefs = [], pids = []) => {
    // pids can be a set or an array
    pids = [...pids].join(",");

    return annotationRefs.includes(pids);
};

// ref|crossLink async actions
export const addRef =
    (pids, location, update = false) =>
    async (dispatch, getState) => {
        const state = getState();
        const allPids = selectPids(state, location);
        pids = sortPidsByDocumentOrder(pids, allPids);
        const annotation = selectActiveAnnotation(state);
        const verseRanges = buildVerseRanges(location, pids, state);
        const contentVersion = selectContentVersion(state, location);
        const docId = selectDocId(state, location);
        const lang = selectLang(state); // might not be able to trust this lang, this should be the lang of the overlay.

        if (doesRefExist(annotation?.refs, pids)) {
            dispatch(showToast({ type: "link-already-exists" }));

            return;
        }

        const ref = {
            name: buildName(location, state, verseRanges),
            contentVersion,
            docId,
            locale: lang,
            pid: pids.join(","),
        };

        if (annotation?.id) {
            dispatch(_addRef(annotation.id, transforms.refFromApi(ref)));
            dispatch(updateHighlightIcon(annotation.id));
            !update && handleFireStudyToolsEvent("link", "create");

            annotationServices
                .addRef(annotation.id, ref)
                .then((returnedAnnotation) => {
                    const returnedRef = returnedAnnotation.refs.find(
                        (item) => item.pid === ref.pid
                    );
                    dispatch(_updateRef(null, returnedRef));
                    dispatch(
                        getCoreContentFragments({
                            uris: [toReducerKey(returnedRef.uri, lang)],
                        })
                    );
                })
                .catch((error) =>
                    dispatch(handleSaveError(error, annotation.id))
                );
        }
    };

export const updateRef =
    (newRefPid, oldRefPid, location) => async (dispatch, getState) => {
        const state = getState();
        const annotation = selectActiveAnnotation(state);

        if (doesRefExist(annotation?.refs, newRefPid)) {
            dispatch(showToast({ type: "link-already-exists" }));
        } else {
            dispatch(removeRef(oldRefPid, true));
            dispatch(addRef(newRefPid, location, true));
            handleFireStudyToolsEvent("link", "update");
        }
    };

export const removeRef =
    (refId, update = false) =>
    async (dispatch, getState) => {
        const state = getState();
        const annotationId = selectActiveAnnotationId(state);

        if (annotationId) {
            dispatch(_removeRef(annotationId, refId));
            dispatch(updateHighlightIcon(annotationId));
            !update && handleFireStudyToolsEvent("link", "delete");

            await annotationServices
                .removeRef(annotationId, refId)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const addBookmark =
    (bookmark, location, bookmarks) => async (dispatch, getState) => {
        let state = getState();
        let annotationId = uuidv4();
        let docId = selectDocId(state, location);
        let lang = selectLang(state);
        let publication = selectPublicationTitle(state, location);
        let title = selectContentTitle(state, location);
        let reference = buildReference(title, bookmark.pid);

        let annotation = {
            id: annotationId,
            docId,
            folders: [],
            lang,
            lastUpdated: new Date().toISOString(),
            note: {},
            source: APP_URL,
            tags: [],
            type: "bookmark",
            bookmark: {
                annotationId,
                sort: 0,
                name: reference,
                offset: "-1",
                publication,
                reference,
                ...bookmark,
            },
        };

        dispatch(_addBookmark(annotation));
        handleFireStudyToolsEvent("bookmark", "create");

        const bookmarkOrder = { [annotationId]: 0 };
        // reorders all bookmarks to account for new bookmark
        // annotationId of bookmarks renamed as bookmarkId to eliminate confusion from bookmark's annotationId
        bookmarks.forEach(({ annotationId: bookmarkId }, i) => {
            // increment each item in bookmarks by 1 position
            Object.assign(bookmarkOrder, {
                [bookmarkId]: i + 1,
            });
        });

        dispatch(_reorderBookmarks(Object.entries(bookmarkOrder)));

        annotationServices
            .addAnnotation(transforms.annotationToApi(annotation))
            .catch((error) => dispatch(handleSaveError(error, annotation.id)));
        annotationServices.reorderBookmarks(bookmarkOrder);
    };

export const _getBookmarks = (bookmarks) => (dispatch) => {
    bookmarks = bookmarks.map(transforms.bookmarkFromApi);
    dispatch(_addBookmarks(bookmarks));
};

export const getBookmarks = () => async (dispatch) => {
    let bookmarks = await annotationServices.getBookmarks();
    dispatch(_getBookmarks(bookmarks));
};

export const reorderBookmarks = (bookmarkOrder) => async (dispatch) => {
    dispatch(_reorderBookmarks(Object.entries(bookmarkOrder)));

    annotationServices.reorderBookmarks(bookmarkOrder);
};

export const updateBookmarkName =
    (name, annotationId) => async (dispatch, getState) => {
        let state = getState();

        annotationId = annotationId || selectActiveAnnotationId(state);

        if (annotationId) {
            dispatch(_updateBookmarkName(annotationId, name));

            annotationServices
                .updateBookmark(annotationId, { name })
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const updateBookmarkLocation =
    (bookmarkLocation, location, annotationId = "") =>
    async (dispatch, getState) => {
        let state = getState();

        annotationId = annotationId || selectActiveAnnotationId(state);

        let bookmark = selectBookmarkById(state, annotationId);
        let docId = selectDocId(state, location);
        let lang = selectLang(state);
        let publication = selectPublicationTitle(state, location);
        let title = selectContentTitle(state, location);

        let reference = buildReference(title, bookmarkLocation.pid);
        let name =
            bookmark.name === bookmark.reference ? reference : bookmark.name;

        bookmarkLocation = {
            docId,
            lang,
            name,
            offset: "-1",
            publication,
            reference,
            ...bookmarkLocation,
        };

        if (annotationId) {
            dispatch(_updateBookmarkLocation(annotationId, bookmarkLocation));
            handleFireStudyToolsEvent("bookmark", "update");

            annotationServices
                .updateBookmark(annotationId, bookmarkLocation)
                .catch((error) =>
                    dispatch(handleSaveError(error, annotationId))
                );
        }
    };

export const createTag = (tag) => async (dispatch, getState) => {
    let state = getState();
    let tags = selectTags(state);

    tag = {
        href: `${STUDY_NOTEBOOK_URL}/tags/${tag.name}`,
        ...tag,
        id: tag.id || uuidv4(),
    };

    if (!tags[tag.id]) {
        dispatch(_replaceTags([...Object.values(tags), tag]));

        await annotationServices.createTag(transforms.tagToApi(tag));
    }

    return selectTagById(getState(), tag.id);
};

export const getTags = () => async (dispatch) => {
    let tags = await annotationServices.getTags();
    tags = tags.map(transforms.tagFromApi);

    dispatch(_replaceTags(tags));
};

export const createFolder = (folder) => async (dispatch, getState) => {
    let state = getState();
    let folders = selectFolders(state);
    const setId = selectActiveSetId(state);

    folder = {
        ...folder,
        id: folder.id || uuidv4(),
        setId,
    };

    if (!folders[folder.id]) {
        dispatch(_replaceFolders([...Object.values(folders), folder]));
        handleFireStudyToolsEvent("notebook", "create");

        await annotationServices.createFolder(transforms.folderToApi(folder));
    }

    return selectFolderById(getState(), folder.id);
};

export const getFolders = () => async (dispatch, getState) => {
    const state = getState();
    const setId = selectActiveSetId(state);
    let folders = await annotationServices.getFolders({ setId });
    folders = transforms.foldersFromApi(folders);

    dispatch(_replaceFolders(folders));
};

export const replaceSets = (sets) => (dispatch, getState) => {
    const state = getState();
    const selectI18nStringById = selectI18nStringByIdBuilder(state);
    // Remove previous defaultSet
    sets = sets.filter((set) => set.id !== null);
    // Get largest sort number
    let maxSortNumber = sets.reduce(
        (maxSortNumber, set) => Math.max(set.sort, maxSortNumber),
        0
    );

    // build the defaultSet set here since there are multiple reducers that need it
    const defaultSet = {
        name: selectI18nStringById("defaultSetName"),
        id: null,
        sort: maxSortNumber + 1,
    };

    dispatch(_replaceSets([...sets, defaultSet]));
};

export const createSet = (name) => async (dispatch, getState) => {
    const state = getState();
    const sets = _selectSets(state);
    const timestamp = new Date().toISOString();
    const newSet = {
        created: timestamp,
        id: uuidv4(),
        name,
        sort: 0,
        timestamp,
        visible: "true",
    };

    dispatch(replaceSets([...sets, newSet]));
    dispatch(changeActiveSet(newSet.id));
    handleFireStudyToolsEvent("study set", "create");
};

export const deleteSet = (setId) => async (dispatch, getState) => {
    // Check if this set is active
    const state = getState();
    const activeSetId = selectActiveSetId(state);

    if (activeSetId === setId) {
        dispatch(changeActiveSet(null));
    }

    dispatch(_deleteSet(setId));

    const { annotationsToDelete, foldersToDelete } =
        await annotationServices.deleteSet(setId);

    dispatch(_deleteAnnotations(annotationsToDelete));
    dispatch(_deleteFolders(foldersToDelete));
};

export const getSets = () => async (dispatch) => {
    let { sets = [] } = await annotationServices.getSets();
    sets = sets.map(transforms.setFromApi);
    const activeSet = sets.filter((set) => set.activeSet)[0];

    dispatch(_changeActiveSet(activeSet?.id || null));
    dispatch(replaceSets(sets));
};

export const reorderSets = (sortMap) => async (dispatch) => {
    const { ...newSortMap } = sortMap;

    // Keep default set in the store
    dispatch(_reorderSets(sortMap));

    // Remove default set, the API doesn't expect it
    await annotationServices.reorderSets(newSortMap);
};

export const updateSet = (set) => async (dispatch) => {
    dispatch(_updateSet(set));

    await annotationServices.updateSet(set.id, transforms.setToApi(set));
};

export const changeActiveSet = (setId) => (dispatch, getState) => {
    const state = getState();
    const activeSet = selectActiveSet(state);

    if (activeSet?.id) {
        const prevActiveSet = { ...activeSet, activeSet: false };
        dispatch(updateSet(prevActiveSet));
    }

    if (setId) {
        const newActiveSet = selectSetById(state, setId);
        const updatedNewActiveSet = { ...newActiveSet, activeSet: true };

        dispatch(updateSet(updatedNewActiveSet));
    }

    handleFireStudyToolsEvent("study set", "switch");

    dispatch(_changeActiveSet(setId));
};
//#endregion async actions
