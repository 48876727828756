import { GET_I18N_RESOURCES } from "../actions/i18n";

export default (state = {}, { type, payload }) => {
    switch (type) {
        case GET_I18N_RESOURCES:
            return {
                ...state,
                [payload.lang]: payload.resources,
            };
        default:
            return state;
    }
};
