import { forwardRef } from "react";
import classes from "classnames";
import {
    Ghost as EdenGhost,
    Icon as EdenIcon,
    Primary as EdenPrimary,
    Secondary as EdenSecondary,
} from "@churchofjesuschrist/eden-buttons";

export const Secondary = forwardRef(({ className, ...props }, ref) => (
    <EdenSecondary className={classes(className)} {...props} ref={ref} />
));
Secondary.displayName = "Secondary";

export const Primary = forwardRef(({ className, ...props }, ref) => (
    <EdenPrimary className={classes(className)} {...props} ref={ref} />
));
Primary.displayName = "Primary";

export const Ghost = forwardRef(({ className, ...props }, ref) => (
    <EdenGhost className={classes(className)} {...props} ref={ref} />
));
Ghost.displayName = "Ghost";

/* TODO: doesn't touch IconButton.js but needs to */
export const Icon = forwardRef(({ className, ...props }, ref) => (
    <EdenIcon className={classes(className)} {...props} ref={ref} />
));
Icon.displayName = "Icon";

export {
    Secondary as SecondaryButton,
    Primary as PrimaryButton,
    Ghost as GhostButton,
    Icon as IconButton,
};
