import { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Toaster.css";
import classes from "classnames";
import { dismissToast } from "../../actions/notifications";
import * as EdenAlerts from "../../theming/components/eden-alert";
import { selectI18nStringById as selectI18nStringByIdBuilder } from "../../selectors";
import { LoggedOffAlert } from "../SignInPrompt";

const config = {
    saved: {
        type: "Success",
        messageKey: "annotationSavedToastMessage",
        aria: {
            role: "status",
            "aria-live": "polite",
            "aria-atomic": "true",
        },
    },
    "annotation-moved": {
        type: "Success",
        messageKey: "annotationMovedToastMessage",
        aria: {
            role: "status",
            "aria-live": "polite",
            "aria-atomic": "true",
        },
    },
    "failed-to-save": {
        type: "Error",
        messageKey: "failedToSaveToastMessage",
        aria: {
            role: "alert",
            "aria-live": "assertive",
            "aria-atomic": "true",
        },
    },
    "annotations-out-of-service": {
        type: "Error",
        messageKey: "annotationsOutOfServiceText",
        aria: {
            role: "alert",
            "aria-live": "assertive",
            "aria-atomic": "true",
        },
    },
    error: {
        type: "Error",
        messageKey: "genericErrorToastMessage",
        aria: {
            role: "alert",
            "aria-live": "assertive",
            "aria-atomic": "true",
        },
    },
    "link-already-exists": {
        type: "Warn",
        messageKey: "linkAlreadyExistsToastMessage",
        aria: {
            role: "alert",
            "aria-live": "assertive",
            "aria-atomic": "true",
        },
    },
    "url-copied": {
        type: "Success",
        messageKey: "urlCopiedToastMessage",
        aria: {
            role: "status",
            "aria-live": "polite",
            "aria-atomic": "true",
        },
    },
};

const Alert = ({
    type = "Info",
    handleClose,
    selectI18nStringById,
    customMessage = "",
}) => {
    customMessage =
        selectI18nStringById(config[type]?.messageKey) || customMessage;
    type = config[type]?.type || type;
    const Component = EdenAlerts[type];

    return type === "logged-off" ? (
        <LoggedOffAlert handleClose={handleClose} />
    ) : (
        <Component onClose={handleClose}>{customMessage}</Component>
    );
};

const Toaster = ({
    type,
    autoHideDuration = 6000,
    onCloseCallback = () => {},
    disableAutoHide = false,
    show = false,
    customMessage,
}) => {
    const portalRoot = useRef(null);
    const dispatch = useDispatch();
    const handleClose = () => dispatch(dismissToast());
    let selectI18nStringById = useSelector(selectI18nStringByIdBuilder);
    const [isHidden, setIsHidden] = useState(true);

    useEffect(() => {
        portalRoot.current = document.getElementById("portalRoot");
    }, []);

    useEffect(() => {
        if (show) {
            setIsHidden(false);
        }

        if (show && !disableAutoHide) {
            const timer = setTimeout(() => {
                handleClose();
                setTimeout(() => onCloseCallback(), 250);
            }, autoHideDuration);

            return () => clearTimeout(timer);
        }
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, disableAutoHide]);

    const handleTransitionEnd = () => {
        setIsHidden(!show);
    };

    return (
        portalRoot.current &&
        ReactDOM.createPortal(
            <div
                onTransitionEnd={handleTransitionEnd}
                className={classes(styles.toaster, show && styles.show)}
                {...config[type]?.aria}
            >
                {(show || !isHidden) && (
                    <Alert
                        handleClose={() => {
                            document.activeElement.blur();
                            handleClose();
                        }}
                        selectI18nStringById={selectI18nStringById}
                        type={type}
                        customMessage={customMessage}
                    />
                )}
            </div>,
            portalRoot.current
        )
    );
};

export default Toaster;
