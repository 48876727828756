import { connect } from "react-redux";
import { selectStyleSheets, selectLoadedStyles } from "../selectors";
import * as styleActions from "../actions/styles";

const mapStateToProps = (state) => ({
    loadedStyles: selectLoadedStyles(state),
    currentLinks: selectStyleSheets(state),
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(mapStateToProps, styleActions, mergeProps);
