const KEYCODE_TAB = 9;

export const isEmpty = (item) => {
    if (item == null) return !item;

    if (Number.isInteger(item.size)) return !item.size;

    item = Object.keys(item);

    return !item.length;
};

export const pickBy = (object, predicate = (x) => x) => {
    if (object == null) return {};

    const entries = Object.entries(object).filter(([, value]) =>
        predicate(value)
    );

    return Object.fromEntries(entries);
};

export const filterUndefined = (obj) => {
    try {
        return JSON.parse(JSON.stringify(obj));
    } catch (err) {
        return pickBy(obj, (value) => value !== undefined);
    }
};

export const closestElement = (node) =>
    node.nodeType === Node.ELEMENT_NODE ? node : node.parentElement;

export const isPromise = (promise) => promise instanceof Promise;

export const isPdf = (src) => {
    const regex = new RegExp(/.*\.pdf$/);
    const isPDF = regex.test(src);

    return isPDF;
};

export const handleKeyDownTrapFocus = (event, startElement, endElement) => {
    const isTabPressed = event.key === "Tab" || event.keyCode === KEYCODE_TAB;

    if (event.shiftKey && isTabPressed) {
        if (startElement.contains(document.activeElement)) {
            event.preventDefault();
            endElement.focus();
        }
    } else if (isTabPressed) {
        if (endElement.contains(document.activeElement)) {
            event.preventDefault();
            startElement.focus();
        }
    }
};

export const getTopPid = ({ fullpage }) => {
    const navbar = document.querySelector("[class*=readerViewHeader-]");

    return fullpage
        ? Array.from(
              document.querySelectorAll("[data-aid-version] header [data-aid]")
          )[0]
        : Array.from(
              document.querySelectorAll("[data-aid-version] [data-aid]")
          ).filter((pid) => pid.offsetTop > navbar.offsetTop)[0];
};

// This function was given from mobile and it is important that it always matches mobile
// for the case of the RecordImpressionsButton so we always reference the same element
export const getNearestSiblingWithAIDAttribute = (element) => {
    if (element === null) {
        return null;
    }

    if (element.attribs["data-aid"] === undefined) {
        let parentElement = element.parent;
        let index = -1;

        for (let i = 0; i < parentElement.children.length; i++) {
            let childElement = parentElement.children[i];

            if (childElement === element) {
                index = i;

                break;
            }
        }

        if (index !== -1) {
            for (let i = index; i >= 0; i--) {
                let childElement = parentElement.children[i];

                // skip empty elements because it has no content to put the note on
                if (childElement.innerText === "") {
                    continue;
                }

                let aid =
                    childElement.attribs && childElement.attribs["data-aid"];

                if (aid !== undefined) {
                    return childElement;
                }
            }
        }
    }

    return element;
};

export const srcSetFromId = ({ id, min = 200, max = 640 }) => {
    // use our standard image sizes
    const sizes = [
        60, 100, 200, 250, 320, 500, 640, 800, 1280, 1600, 1920, 3840,
    ];

    // filter out sizes that are too small or too large based on provided limits
    const filteredSizes = sizes.filter((size) => size >= min && size <= max);

    // create the srcSet
    const srcSet = filteredSizes
        .map((size) => {
            // This url structure sets the width but not the height so the height is whatever it needs to be, the `${size}%2c` "%2c" is a comma and not setting the height. The `!` is a flag to not upscale the image bigger than the original size.
            return `https://www.churchofjesuschrist.org/imgs/${id}/full/!${size}%2C/0/default ${size}w`;
        })
        .join(", ");

    return srcSet;
};
