import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import isBrowser from "is-in-browser";
import { createBrowserHistory, createMemoryHistory } from "history";

import { get } from "@churchofjesuschrist/universal-env";

import annotations from "./reducers/annotations";
import coreContent from "./reducers/coreContent";
import i18n from "./reducers/i18n";
import home from "./reducers/home";
import library from "./reducers/library";
import localSettings from "./reducers/localSettings";
import notifications from "./reducers/notifications";
import reader from "./reducers/reader";
import sidePanel from "./reducers/sidePanel";
import styles from "./reducers/styles";
import system from "./reducers/system";

const { ALLOW_REDUX_DEV_TOOLS } = get();

export const history = isBrowser
    ? createBrowserHistory()
    : createMemoryHistory();

// preview only reducers
const preview = (state = false) => state;

// server side only reducers
const headers = (state = {}) => state;

const buildRootReducer = (isPreview = false) => {
    const reducers = {
        annotations,
        coreContent,
        home,
        i18n,
        library,
        localSettings,
        notifications,
        reader,
        sidePanel,
        styles,
        system,
    };

    const clientProdReducer = combineReducers(reducers);
    const clientPreviewReducer = combineReducers({ preview, ...reducers });
    const serverSideReducer = combineReducers({
        preview,
        headers,
        ...reducers,
    });

    return isBrowser
        ? isPreview
            ? clientPreviewReducer
            : clientProdReducer
        : serverSideReducer;
};

function b64DecodeUnicode(str) {
    // Going backwards: from bytestream, to percent-encoding, to original string.
    return decodeURIComponent(
        atob(str)
            .split("")
            .map(function (c) {
                return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
    );
}

// export a store creator factory with initial state if present...
export default (localInitialState, enhancer = (x) => x) => {
    let initialState =
        isBrowser && window.__INITIAL_STATE__
            ? JSON.parse(b64DecodeUnicode(window.__INITIAL_STATE__))
            : localInitialState;

    !initialState.preview && delete initialState.preview;

    const rootReducer = buildRootReducer(initialState.preview);

    const reduxMiddleware = compose(
        applyMiddleware(reduxThunk),
        isBrowser &&
            ALLOW_REDUX_DEV_TOOLS === "1" &&
            window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined
            ? window.__REDUX_DEVTOOLS_EXTENSION__()
            : (f) => f,
        enhancer
    );

    const store = createStore(rootReducer, initialState, reduxMiddleware);

    return store;
};
