import { fetchContent, fetchToc } from "../services/blt";
import { setHttpResponseCode } from "./system";
import { selectContent, selectToc } from "../selectors";
import { toReducerKey } from "../../util/reducer-helpers";
import { isEmpty } from "../../util/utils";
import { removeAppBase } from "../../util/uri-utils";

export const GET_TOC_DATA = "GET_TOC_DATA";
export const GET_READER_CONTENT = "GET_READER_CONTENT";
export const ADD_INITIAL_CONTENT = "ADD_INITIAL_CONTENT";
export const CLEAR_TOC_COLLECTION_OVERRIDES = "CLEAR_TOC_COLLECTION_OVERRIDES";
export const SET_TOC_COLLECTION_OVERRIDE = "SET_TOC_COLLECTION_OVERRIDE";

export const addTableOfContents = (book, lang = "eng") => ({
    type: GET_TOC_DATA,
    payload: {
        [toReducerKey(book.uri, lang)]: book,
    },
});

export const addReaderContent = (content, lang = "eng") => ({
    type: GET_READER_CONTENT,
    payload: {
        [toReducerKey(content.uri, lang)]: content,
    },
});

export const clearTocCollectionOverrides = () => ({
    type: CLEAR_TOC_COLLECTION_OVERRIDES,
});

export const setTocCollectionOverride = (
    content,
    collection,
    lang = "eng"
) => ({
    type: SET_TOC_COLLECTION_OVERRIDE,
    payload: {
        bookKey: toReducerKey(content.tableOfContentsUri, lang),
        parentNameOverride: collection.title,
        parentUriOverride: removeAppBase(collection.uri),
    },
});

export const getReaderContent =
    (params, headers) => async (dispatch, getState) => {
        let state = getState();
        let { lang, uri } = params;

        let status = 200;
        let contentKey = toReducerKey(uri, lang);
        let content = selectContent(state, contentKey);

        if (isEmpty(content)) {
            try {
                content = await fetchContent(params, headers);
            } catch (err) {
                let { firstContentUri } = await getReaderTOC(params, headers);

                status = err.status;
                content = err;

                if (firstContentUri) {
                    await getReaderContent(
                        { ...params, uri: firstContentUri },
                        headers
                    );
                    content.redirect = toReducerKey(firstContentUri, lang);
                }
            }

            dispatch(setHttpResponseCode(status));
            dispatch(addReaderContent(content, params.lang));
        }

        return content;
    };

export const getReaderTOC = (params, headers) => async (dispatch, getState) => {
    let state = getState();
    let { lang, uri } = params;

    let status = 200;
    let bookKey = toReducerKey(uri, lang);
    let book = selectToc(state, bookKey);

    if (isEmpty(book)) {
        try {
            book = await fetchToc(params, headers);
        } catch (err) {
            status = err.status;
            book = err;
        }

        dispatch(setHttpResponseCode(status));
        dispatch(addTableOfContents(book, params.lang));
    }

    return book;
};

export const getReaderData =
    (params, headers) => async (dispatch, getState) => {
        let promises = [
            getReaderContent(params, headers)(dispatch, getState),
            getReaderTOC(params, headers)(dispatch, getState),
        ];

        let [content, book] = await Promise.all(promises);

        return { content, book };
    };
