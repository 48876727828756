import { connect } from "react-redux";
import * as notificationActions from "../actions/notifications";

const mapStateToProps = (state) => ({
    ...state.system,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...stateProps,
    ...dispatchProps,
    ...ownProps,
});

export default connect(
    mapStateToProps,
    {
        ...notificationActions,
    },
    mergeProps
);
