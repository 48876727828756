import isBrowser from "is-in-browser";

export const getScrollingElement = () =>
    !isBrowser
        ? null
        : document.querySelector("#content")
        ? document.documentElement
        : document.scrollingElement;

export const getScrollTop = () => {
    let scrollingElement = getScrollingElement();

    return scrollingElement && scrollingElement.scrollTop;
};
