import { Component } from "react";
import { connect } from "react-redux";
import { getLibraryData } from "../actions/library";
import { getHomeData } from "../actions/home";
import { asyncCheckLogin } from "../actions/system";
import { selectLang, selectLibrary } from "../selectors";
import mem from "mem";
import analytics from "../../util/analytics";
import { prependAppBase } from "../../util/uri-utils";
import loadable from "@loadable/component";
import Loading from "../components/Loading";

const Home = loadable(
    () => import(/* webpackChunkName: 'home' */ "../templates/Home"),
    { fallback: <Loading /> }
);

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;
    let library = selectLibrary(state, location);

    return {
        additionalItems: state.home.additionalItems,
        featureItems: state.home.featureItems,
        library,
        homeStatus: state.home.status,
        lang: selectLang(state),
        libraryStatus: library.status,
        location,
        meta: state.home.meta,
        showArchivedContent: state.localSettings.showArchivedContent,
        streamItems: state.home.streamItems,
        system: state.system,
    };
};

const mapLangToActions = mem((dispatchProps, lang) => ({
    getHomeData: (params, headers) =>
        dispatchProps.getHomeData({ lang, ...params }, headers),
    getLibraryData: (params, headers) =>
        dispatchProps.getLibraryData({ lang, ...params }, headers),
}));

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const lang = stateProps.lang;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        ...mapLangToActions(dispatchProps, lang),
    };
};

const storeConnector = connect(
    mapStateToProps,
    {
        asyncCheckLogin,
        getHomeData,
        getLibraryData,
    },
    mergeProps
);

class HomeContainer extends Component {
    static async updateInitialStore(store) {
        let state = store.getState();
        let context = state.preview ? "preview" : undefined;
        let dispatch = store.dispatch;
        let headers = { cookie: state.headers.cookie };
        let lang = selectLang(state);

        let actions = [
            getLibraryData({ context, lang, uri: "/lib" }, headers),
            getHomeData({ context, lang }, headers),
        ];

        await Promise.all(
            actions.map((action) => action(dispatch, store.getState))
        );
    }

    componentDidMount = async () => {
        const {
            asyncCheckLogin,
            getHomeData,
            getLibraryData,
            lang,
            library,
            meta,
        } = this.props;

        !library.loaded &&
            getLibraryData({ uri: prependAppBase("/lib"), lang });
        !meta && getHomeData();

        analytics.firePageViewEvent(asyncCheckLogin, {
            page: {
                info: {
                    name: "gospel library landing",
                    language: lang,
                },
                category: {
                    type: "landing",
                },
            },
        });
    };

    render() {
        return <Home {...this.props} />;
    }
}

export default storeConnector(HomeContainer);
