import { getCoreContentFragments } from "./coreContent";
import { getDynamicData } from "./dynamic";
import { currentReducerKey, toReducerKey } from "../../util/reducer-helpers";
import { includesAppBase, urlToDotNotation } from "../../util/uri-utils";
import { selectFloatingPanels } from "../selectors";

export const GET_CROSSREF = "GET_CROSSREF";
export const GET_FOOTNOTE = "GET_FOOTNOTE";
export const ADD_FLOATING_PANEL = "ADD_FLOATING_PANEL";
export const REMOVE_FLOATING_PANEL = "REMOVE_FLOATING_PANEL";
export const REMOVE_FLOATING_PANEL_BY_ID = "REMOVE_FLOATING_PANEL_BY_ID";
export const CLEAR_FLOATING_PANELS = "CLEAR_FLOATING_PANELS";
export const SET_PANEL = "SET_PANEL";
export const SET_SUB_PANEL = "SET_SUB_PANEL";

export const setActivePanel = (payload) => ({
    type: SET_PANEL,
    payload,
});

export const setActiveSubPanel = (payload) => ({
    type: SET_SUB_PANEL,
    payload,
});

export const addFootnote = (id, references = []) => ({
    type: GET_FOOTNOTE,
    payload: { id, references },
});

const _addFloatingPanel = (payload) => ({
    type: ADD_FLOATING_PANEL,
    payload,
});

export const removeFloatingPanel = () => ({
    type: REMOVE_FLOATING_PANEL,
});

export const removeFloatingPanelById = (id) => ({
    type: REMOVE_FLOATING_PANEL_BY_ID,
    payload: id,
});

export const clearFloatingPanels = () => ({
    type: CLEAR_FLOATING_PANELS,
});

export const addFloatingPanel = (panelData) => async (dispatch, getState) => {
    const reducerKey = currentReducerKey();
    const state = getState();
    const floatingPanels = selectFloatingPanels(state, reducerKey);

    // the same panel can't be opened while it's currently open
    if (panelData.id === floatingPanels[floatingPanels.length - 1]?.id) return;

    // prevent duplicate keys if the same panel is open further down in the panel list
    const duplicatePanels = floatingPanels.filter(
        ({ id }) => panelData.id === id
    );
    panelData = {
        ...panelData,
        key: `${panelData.id}_${duplicatePanels.length}`,
    };

    // only one editor can be open at a time
    if (panelData.type === "editor") {
        floatingPanels
            .filter((panel) => panel.type === "editor")
            .forEach((panel) => dispatch(removeFloatingPanelById(panel.id)));
    }

    dispatch(_addFloatingPanel(panelData));
};

export const removeEditorPanel = () => (dispatch, getState) => {
    const reducerKey = currentReducerKey();
    const state = getState();
    const floatingPanels = selectFloatingPanels(state, reducerKey);

    floatingPanels
        .filter((panel) => panel.type === "editor")
        .forEach((panel) => dispatch(removeFloatingPanelById(panel.id)));
};

export const getCrossRefData =
    ({ uris, lang }, headers) =>
    (dispatch, getState) => {
        let promises = [];
        let { fullUris, fragmentUris } = uris.reduce(
            (uris, crossRef) => {
                let uri = urlToDotNotation(crossRef.href);

                uris.fragmentUris.push(toReducerKey(uri, lang));
                !uri.includes(".") && uris.fullUris.push(uri);

                return uris;
            },
            { fullUris: [], fragmentUris: [] }
        );

        if (fragmentUris.length) {
            promises.push(
                getCoreContentFragments({ uris: fragmentUris }, headers)(
                    dispatch,
                    getState
                )
            );
        }

        if (fullUris.length) {
            fullUris.forEach((uri) =>
                promises.push(
                    getDynamicData({ uri, lang }, headers)(dispatch, getState)
                )
            );
        }

        const data = Promise.all(promises);

        return data;
    };

export const getFootnoteData =
    (id, { uris, lang }, headers) =>
    (dispatch, getState) => {
        if (!uris) return Promise.resolve();

        uris = uris
            .filter(({ href }) => /^\//.test(href) || includesAppBase(href))
            .map((footnote) =>
                toReducerKey(urlToDotNotation(footnote.href), lang)
            );

        return getCoreContentFragments({ uris }, headers)(
            dispatch,
            getState
        ).then((response) => dispatch(addFootnote(id, response)));
    };
