import { combineReducers } from "redux";
import * as types from "../actions/annotations";
import {
    addItemToSubState,
    buildHighlightKeys,
    concatItemsToSubState,
    determineIconType,
    filterItemFromSubState,
    reduceEntries,
    spreadItemsToSubState,
    toHighlightKey,
} from "../../util/reducer-helpers";
import { filterUndefined } from "../../util/utils";
import { get } from "@churchofjesuschrist/universal-env";

const { APP_PATHNAME } = get();

export const annotations = (state = {}, { type, payload = [] }) => {
    switch (type) {
        case types.ADD_HIGHLIGHTS:
            return payload.reduce(
                (annotations, annotation) => ({
                    ...annotations,
                    [annotation.id]: {
                        ...annotation,
                        highlights: buildHighlightKeys(
                            annotation.id,
                            annotation.highlights
                        ),
                        refs: annotation.refs.map((ref) => ref.pid),
                    },
                }),
                state
            );

        case types.UPDATE_HIGHLIGHT_OFFSETS: {
            return spreadItemsToSubState(payload.annotationId, state, {
                highlights: buildHighlightKeys(
                    payload.annotationId,
                    payload.offsets
                ),
                highlightUri: payload.uri,
            });
        }

        case types.UPDATE_ANNOTATION_SET: {
            return {
                ...state,
                [payload.annotationId]: {
                    ...state[payload.annotationId],
                    setId: payload.setId,
                },
            };
        }

        case types.REMOVE_REF: {
            const annotation = state[payload.annotationId];
            const refs = annotation.refs;
            const refIndex = annotation.refs.lastIndexOf(payload.refId);

            return {
                ...state,
                [annotation.id]: {
                    ...annotation,
                    refs: refs
                        .slice(0, refIndex)
                        .concat(refs.slice(refIndex + 1)),
                },
            };
        }

        case types.ADD_REF: {
            const annotation = state[payload.annotationId];
            const refs = annotation.refs;

            return {
                ...state,
                [annotation.id]: {
                    ...annotation,
                    refs: [...refs, payload.ref.pid],
                },
            };
        }

        case types.ADD_BOOKMARKS:
            return payload.reduce(
                (annotations, annotation) => ({
                    ...annotations,
                    [annotation.id]: {
                        ...annotation,
                        bookmark: undefined,
                    },
                }),
                state
            );

        case types.UPDATE_BOOKMARK_LOCATION:
            return spreadItemsToSubState(payload.annotationId, state, {
                docId: payload.docId,
                lang: payload.lang,
                uri: payload.uri.split(".")[0],
            });

        case types.DELETE_ANNOTATION: {
            if (!state[payload]) return state;

            let {
                // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...newState
                [payload]: deletedAnnotation,
                ...newState
            } = state;

            return newState;
        }

        case types.DELETE_ANNOTATIONS: {
            if (!payload.some((annotationId) => state[annotationId]))
                return state;

            return Object.entries(state)
                .filter(([key]) => !payload.includes(key))
                .reduce(reduceEntries, {});
        }

        case types.ADD_TAG:
            return concatItemsToSubState(
                payload.annotationId,
                state,
                "tags",
                payload.tag
            );

        case types.REMOVE_TAG:
            return filterItemFromSubState(
                payload.annotationId,
                state,
                "tags",
                payload.tagId
            );

        case types.ADD_FOLDER:
            return concatItemsToSubState(
                payload.annotationId,
                state,
                "folders",
                payload.folder.id
            );

        case types.REMOVE_FOLDER:
            return filterItemFromSubState(
                payload.annotationId,
                state,
                "folders",
                payload.folderId
            );

        case types.UPDATE_NOTE:
            return {
                ...state,
                [payload.annotationId]: spreadItemsToSubState(
                    "note",
                    state[payload.annotationId],
                    payload.note
                ),
            };

        default:
            return state;
    }
};

export const activeAnnotation = (state = null, { type, payload }) => {
    switch (type) {
        case types.CHANGE_ACTIVE_ANNOTATION: {
            // Might need this to debug a strange bug with the side panels.
            // console.log('CHANGE_ACTIVE_ANNOTATION payload:', payload);
            return payload;
        }

        default:
            return state;
    }
};

export const initialized = (state = {}, { type, payload }) => {
    switch (type) {
        case types.INITIALIZE_ANNOTATIONS:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
};

const filterHighlightByAnnotationId = (state, annotationId) =>
    Object.entries(state)
        .filter(([, highlight]) => highlight.annotationId !== annotationId)
        .reduce(reduceEntries, {});

const findHighlightsByAnnotationId = (state, annotationId) =>
    Object.values(state).filter(
        (highlight) => highlight.annotationId === annotationId
    );

export const highlights = (state = {}, { type, payload = [] }) => {
    switch (type) {
        case types.ADD_HIGHLIGHTS:
            return payload.reduce(
                (highlights, annotation) => ({
                    ...highlights,
                    ...annotation.highlights.reduce(
                        (highlights, highlight, i) => ({
                            ...highlights,
                            [toHighlightKey(annotation.id, highlight.pid)]: {
                                ...highlight,
                                annotationId: annotation.id,
                                docId: annotation.docId,
                                first: i === 0,
                                icon:
                                    i === 0
                                        ? determineIconType(annotation)
                                        : undefined,
                            },
                        }),
                        {}
                    ),
                }),
                state
            );

        case types.DELETE_ANNOTATION: {
            const highlightsToDelete = findHighlightsByAnnotationId(
                state,
                payload
            );

            return highlightsToDelete.length
                ? filterHighlightByAnnotationId(state, payload)
                : state;
        }

        case types.UPDATE_HIGHLIGHT_OFFSETS: {
            let { clear, color, docId, icon, underline } =
                findHighlightsByAnnotationId(state, payload.annotationId)[0] ||
                {};

            return payload.offsets.reduce(
                (highlights, offset, i) =>
                    spreadItemsToSubState(
                        toHighlightKey(payload.annotationId, offset.pid),
                        highlights,
                        {
                            ...offset,
                            annotationId: payload.annotationId,
                            clear,
                            color,
                            docId,
                            first: i === 0,
                            icon,
                            underline,
                        }
                    ),
                filterHighlightByAnnotationId(state, payload.annotationId)
            );
        }

        case types.UPDATE_HIGHLIGHT_STYLE:
            return Object.entries(state)
                .map(([key, highlight]) =>
                    highlight.annotationId === payload.annotationId
                        ? [
                              key,
                              {
                                  ...highlight,
                                  ...filterUndefined(payload.style),
                              },
                          ]
                        : [key, highlight]
                )
                .reduce(reduceEntries, {});

        case types.UPDATE_HIGHLIGHT_ICON:
            return addItemToSubState(payload.id, state, "icon", payload.icon);

        default:
            return state;
    }
};

export const refs = (state = {}, { type, payload }) => {
    switch (type) {
        case types.ADD_HIGHLIGHTS:
            return payload.reduce(
                (state, annotation) =>
                    annotation.refs.reduce(
                        (refs, ref) =>
                            Object.assign(refs, {
                                [ref.pid]: {
                                    ...ref,
                                    href: `${APP_PATHNAME}${ref.uri}`,
                                },
                            }),
                        state
                    ),
                state
            );

        case types.UPDATE_REF:
        case types.ADD_REF:
            return spreadItemsToSubState(payload.ref.pid, state, {
                id: payload.ref.pid,
                href: `${APP_PATHNAME}${payload.ref.uri}`,
                ...payload.ref,
            });

        default:
            return state;
    }
};

export const bookmarks = (state = {}, { type, payload = [] }) => {
    switch (type) {
        case types.ADD_BOOKMARKS:
            return payload.reduce(
                (bookmarks, { id: annotationId, bookmark, docId, lang }) => ({
                    ...bookmarks,
                    [annotationId]: { ...bookmark, annotationId, docId, lang },
                }),
                state
            );

        case types.UPDATE_BOOKMARK_NAME:
            return spreadItemsToSubState(payload.annotationId, state, payload);

        case types.UPDATE_BOOKMARK_LOCATION:
            return spreadItemsToSubState(payload.annotationId, state, payload);

        case types.DELETE_ANNOTATION: {
            if (!state[payload]) return state;

            let {
                // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...newState
                [payload]: deletedBookmark,
                ...newState
            } = state;

            return newState;
        }

        default:
            return state;
    }
};

const sortBookmarks = (bookmark1, bookmark2) => {
    let diff = bookmark1.sort - bookmark2.sort;

    // tie breaker
    if (!diff) {
        diff =
            Date.parse(bookmark2.lastUpdated) -
            Date.parse(bookmark1.lastUpdated);
    }

    return diff;
};

const findBookmarkByAnnotationId = (annotationId) => (bookmark) =>
    bookmark.annotationId === annotationId;

export const bookmarkOrder = (state = [], { type, payload = [] }) => {
    switch (type) {
        case types.ADD_BOOKMARKS:
            return payload
                .map(({ id: annotationId, bookmark, lastUpdated }) => ({
                    annotationId,
                    sort: bookmark.sort || 0,
                    lastUpdated,
                }))
                .concat(state)
                .filter(
                    ({ annotationId }, i, bookmarkOrder) =>
                        bookmarkOrder.findIndex(
                            findBookmarkByAnnotationId(annotationId)
                        ) === i
                )
                .sort(sortBookmarks);

        case types.DELETE_ANNOTATION: {
            let newOrder = [...state];
            const index = newOrder.findIndex(
                findBookmarkByAnnotationId(payload)
            );

            if (index === -1) return state;

            newOrder.splice(index, 1);

            return newOrder.map((bookmark, i) => ({ ...bookmark, sort: i }));
        }

        case types.REORDER_BOOKMARKS:
            return payload
                .map(([annotationId, sort]) => ({
                    ...state.find(findBookmarkByAnnotationId(annotationId)),
                    sort,
                }))
                .concat(state)
                .filter(
                    ({ annotationId }, i, bookmarkOrder) =>
                        bookmarkOrder.findIndex(
                            findBookmarkByAnnotationId(annotationId)
                        ) === i
                )
                .sort(sortBookmarks);

        default:
            return state;
    }
};

export const tags = (state = {}, { type, payload }) => {
    switch (type) {
        case types.REPLACE_TAGS:
            return payload.reduce(
                (tags, tag) => ({
                    ...tags,
                    [tag.id]: tag,
                }),
                {}
            );

        default:
            return state;
    }
};

export const folders = (state = {}, { type, payload }) => {
    switch (type) {
        case types.ADD_FOLDER:
            return spreadItemsToSubState(payload.folder.id, state, {
                name: payload.folder.name,
                id: payload.folder.id,
            });

        case types.REPLACE_FOLDERS:
            return payload.reduce(
                (folders, folder) => ({
                    ...folders,
                    [folder.id]: folder,
                }),
                {}
            );

        case types.DELETE_FOLDERS: {
            return Object.entries(state)
                .filter(([key]) => !payload.includes(key))
                .reduce(reduceEntries, {});
        }
        default:
            return state;
    }
};

// Annotation Sets:
export const sets = (state = [], { type, payload = [] }) => {
    switch (type) {
        case types.REPLACE_SETS:
            return payload;

        case types.UPDATE_SET: {
            const sets = [...state];

            const setIndex = sets.findIndex(({ id }) => id === payload.id);
            sets[setIndex] = { ...sets[setIndex], ...payload };

            return sets;
        }

        case types.DELETE_SET:
            return state.filter((set) => set.id !== payload);

        default:
            return state;
    }
};

const sortBySortKeyAndDate = (obj1, obj2) => {
    let diff = (obj1.sort || 0) - (obj2.sort || 0);

    // tie breaker
    if (!diff) {
        diff = Date.parse(obj2.timestamp) - Date.parse(obj1.timestamp);
    }

    return diff;
};

// TODO: do the same treatment for bookmarks in the 1.5 update card for GLO
// TODO: bookmarks need a getBookmarks endpoint in the annotations api that
// returns the bookmarks and their sortmap to make the above possible
const setsToOrderArray = (sets) =>
    sets.sort(sortBySortKeyAndDate).map(({ id }) => id);

const sortMapToOrderArray = (sortMap) =>
    Object.entries(sortMap)
        .sort(([, sort1], [, sort2]) => sort1 - sort2)
        .map(([id]) => (id === "null" ? null : id));

export const setOrder = (state = [], { type, payload = [] }) => {
    switch (type) {
        case types.REPLACE_SETS:
            return setsToOrderArray(payload);

        case types.DELETE_SET:
            return state.filter((setId) => setId !== payload);

        case types.REORDER_SETS:
            return sortMapToOrderArray(payload);

        default:
            return state;
    }
};

export const activeSetId = (state = null, { type, payload }) => {
    switch (type) {
        case types.CHANGE_ACTIVE_SET:
            return payload;

        default:
            return state;
    }
};

export default combineReducers({
    activeAnnotation,
    activeSetId,
    annotations,
    bookmarkOrder,
    bookmarks,
    folders,
    highlights,
    initialized,
    refs,
    setOrder,
    sets,
    tags,
});
