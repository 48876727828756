import styles from "./eden-alert.css";
import classes from "classnames";
import {
    Alert as EdenAlert,
    Error as EdenError,
    Info as EdenInfo,
    Success as EdenSuccess,
    Warn as EdenWarn,
    Overlay as EdenOverlay,
    Modal as EdenModal,
} from "@churchofjesuschrist/eden-alert";

export const Alert = ({ children, className, ...props }) => (
    <EdenAlert
        className={classes(styles.alert, styles.basic, className)}
        {...props}
    >
        {children}
    </EdenAlert>
);

export const Error = ({ children, className, ...props }) => (
    <EdenError className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenError>
);

export const Info = ({ children, className, ...props }) => (
    <EdenInfo className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenInfo>
);

export const Success = ({ children, className, ...props }) => (
    <EdenSuccess className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenSuccess>
);

export const Warn = ({ children, className, ...props }) => (
    <EdenWarn className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenWarn>
);

export const Overlay = ({ children, className, ...props }) => (
    <EdenOverlay className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenOverlay>
);

export const Modal = ({ children, className, ...props }) => (
    <EdenModal className={classes(styles.alert, className)} {...props}>
        {children}
    </EdenModal>
);

export {
    Error as ErrorAlert,
    Info as InfoAlert,
    Success as SuccessAlert,
    Warn as WarnAlert,
    Overlay as OverlayAlert,
    Modal as ModalAlert,
};
