import { memo } from "react";
import { connect } from "react-redux";
import { selectErrorByStatus } from "../selectors";
import { withRouter } from "react-router-dom";
import loadable from "@loadable/component";
import Loading from "../components/Loading";

const Error = loadable(
    () => import(/* webpackChunkName: 'error' */ "../templates/Error"),
    { fallback: <Loading /> }
);

const mapStateToProps = (state, ownProps) => ({
    status: state.system.status,
    selectErrorByStatus: selectErrorByStatus(state),
    location: ownProps.location,
});

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
});

const storeConnector = connect(mapStateToProps, null, mergeProps);

const NotFoundContainer = ({ status, ...props }) => (
    <Error {...props} status={status !== 200 ? status : 404} />
);

const NotFoundContainerMemo = memo(
    NotFoundContainer,
    (prevProps, nextProps) => nextProps.loading
);

export default withRouter(storeConnector(NotFoundContainerMemo));
