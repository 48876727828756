export const ADD_STYLE_SHEET = "ADD_STYLE_SHEET";
export const REMOVE_STYLE_SHEET = "REMOVE_STYLE_SHEET";
export const RESET_STYLE_SHEETS = "RESET_STYLE_SHEETS";
export const STYLE_SHEET_LOADED = "STYLE_SHEET_LOADED";

export const addStyleSheet = (styleSheet) => ({
    type: ADD_STYLE_SHEET,
    payload: {
        key: styleSheet.href,
        styleSheet,
    },
});

export const removeStyleSheet = (styleSheet) => ({
    type: REMOVE_STYLE_SHEET,
    payload: { key: styleSheet.href },
});

export const resetStyleSheets = () => ({
    type: RESET_STYLE_SHEETS,
});

export const styleSheetLoaded = (styleSheet) => ({
    type: STYLE_SHEET_LOADED,
    payload: { key: styleSheet.href },
});
