import "isomorphic-fetch";
import { addAnnotationAPIHeaders } from "../../util/fetch-utils";
import { get } from "@churchofjesuschrist/universal-env";

const { ANNOTATIONS_API } = get();

const buildUris = (uris, uri) => `${uris}uris=${encodeURIComponent(uri)}&`;

export const fetchCoreContentFragments = (
    { uris = [], lang = "" },
    headers
) => {
    if (!uris.length) {
        return Promise.reject({
            status: 400,
            message: "uris cannot be empty.",
        });
    }

    let queryString = `${uris.reduce(buildUris, "?").slice(0, -1)}${
        lang && `&lang=${lang}`
    }`;
    let url = `${ANNOTATIONS_API}/content/api/v3${queryString}`;
    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init)
        .then((response) =>
            response.ok ? response.json() : Promise.reject(response)
        )
        .catch((err) => Promise.reject(err));
};
export const fetchCoreContentFragmentsAsPost = (
    { uris = [], lang = "" },
    headers
) => {
    if (!uris.length) {
        return Promise.reject({
            status: 400,
            message: "uris cannot be empty.",
        });
    }

    let url = `${ANNOTATIONS_API}/content/api/v3${lang && `?lang=${lang}`}`;
    let init = {
        method: "POST",
        mode: "cors",
        compress: true,
        credentials: "include",
        body: JSON.stringify({ uris }),
        headers: { "Content-Type": "application/json" },
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init)
        .then((response) =>
            response.ok ? response.json() : Promise.reject(response)
        )
        .catch((err) => Promise.reject(err));
};
