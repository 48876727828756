import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getBookmarks, getSets } from "../actions/annotations";
import { getLibraryData } from "../actions/library";
import { clearTocCollectionOverrides } from "../actions/reader";
import { setActivePanel } from "../actions/sidePanel";
import { asyncCheckLogin } from "../actions/system";
import {
    selectActivePanel,
    selectAnnotationsOutOfService,
    selectLoggedIn,
    selectContent,
    selectI18nStringById,
    selectLang,
    selectLibrary,
    selectShowArchivedContent,
    selectSortedBookmarks,
} from "../selectors";
import analytics from "../../util/analytics";
import { buildCategoryTypes } from "../../util/type-utils";
import loadable from "@loadable/component";
import Loading from "../components/Loading";

const Library = loadable(
    () => import(/* webpackChunkName: 'library' */ "../templates/Library"),
    { fallback: <Loading /> }
);

const mapStateToProps = (state, ownProps) => {
    let location = ownProps.location;
    let library = selectLibrary(state, location);

    return {
        activePanel: selectActivePanel(state),
        annotationsOutOfService: selectAnnotationsOutOfService(state),
        bookmarks: selectSortedBookmarks(state),
        content: selectContent(state, location),
        dir: state.system.dir,
        i18n: state.i18n,
        lang: selectLang(state),
        library,
        location,
        loggedIn: selectLoggedIn(state),
        selectI18nStringById: selectI18nStringById(state),
        showArchivedContent: selectShowArchivedContent(state),
        status: library.status,
    };
};

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
});

const storeConnector = connect(
    mapStateToProps,
    {
        asyncCheckLogin,
        clearTocCollectionOverrides,
        getBookmarks,
        getSets,
        setActivePanel,
    },
    mergeProps
);

class LibraryContainer extends Component {
    static async updateInitialStore(store, uri) {
        let state = store.getState(),
            context = state.preview ? "preview" : undefined,
            dispatch = store.dispatch,
            lang = selectLang(state);

        let actions = [getLibraryData({ context, lang, uri })];

        await Promise.all(
            actions.map((action) => action(dispatch, store.getState))
        );
    }

    componentDidMount() {
        this.clearTocCollectionOverrides();
        this.getSecureData();
        this.firePageViewEvent();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.library?.uri !== this.props.library?.uri) {
            this.firePageViewEvent();
        }

        if (!prevProps.loggedIn && this.props.loggedIn) {
            this.getSecureData();
        }
    }

    clearTocCollectionOverrides() {
        const { clearTocCollectionOverrides, crossLinkMode } = this.props;

        !crossLinkMode && clearTocCollectionOverrides();
    }

    firePageViewEvent = () => {
        const { asyncCheckLogin, crossLinkMode, lang, library } = this.props;

        !crossLinkMode &&
            library.loaded &&
            analytics.firePageViewEvent(asyncCheckLogin, {
                page: {
                    info: {
                        breadcrumbs: library.breadCrumbs.map(({ uri }) =>
                            uri.split("/").pop()
                        ),
                        language: lang,
                    },
                    category: {
                        ...buildCategoryTypes(library.uri),
                        type: "navigation",
                    },
                },
            });
    };

    async getSecureData() {
        const {
            annotationsOutOfService,
            asyncCheckLogin,
            crossLinkMode,
            getBookmarks,
            getSets,
            loggedIn,
        } = this.props;

        const annotationAvailable = !annotationsOutOfService && !crossLinkMode;

        if ((loggedIn || (await asyncCheckLogin())) && annotationAvailable) {
            getBookmarks();
            getSets();
        }
    }

    render() {
        return <Library {...this.props} />;
    }
}

export default withRouter(storeConnector(LibraryContainer));
