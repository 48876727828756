/* eslint-disable react/display-name */
import { forwardRef } from "react";
import { compose } from "redux";

export const composeConnectors = (...connectors) => {
    let combinedConnector = compose(...connectors);

    return (Child) => {
        const Container = combinedConnector(
            ({
                // eslint-disable-next-line no-unused-vars -- define so it isn't included in ...tag
                ownProps,
                origOwnProps,
                origRef,
                ...props
            }) => <Child ref={origRef} {...props} ownProps={origOwnProps} />
        );

        return forwardRef((props, ref) => (
            <Container origRef={ref} origOwnProps={props} />
        ));
    };
};
