import {
    ADD_STYLE_SHEET,
    REMOVE_STYLE_SHEET,
    RESET_STYLE_SHEETS,
    STYLE_SHEET_LOADED,
} from "../actions/styles.js";

import {
    addItemToSubState,
    removeItemFromSubState,
} from "../../util/reducer-helpers";

export default (
    state = { styleSheets: {}, loadedStyles: [] },
    { type, payload = {} }
) => {
    switch (type) {
        case ADD_STYLE_SHEET:
            return !state.styleSheets[payload.key] && payload.styleSheet.href
                ? addItemToSubState(
                      "styleSheets",
                      state,
                      payload.key,
                      payload.styleSheet
                  )
                : state;

        case REMOVE_STYLE_SHEET:
            return {
                ...removeItemFromSubState("styleSheets", state, payload.key),
                loadedStyles: state.loadedStyles.filter(
                    (name) => name !== payload.key
                ),
            };

        case RESET_STYLE_SHEETS:
            return { styleSheets: {}, loadedStyles: [] };

        case STYLE_SHEET_LOADED:
            return {
                ...state,
                loadedStyles: [...state.loadedStyles, payload.key],
            };

        default:
            return state;
    }
};
