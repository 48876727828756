import { useEffect } from "react";
import styles from "./style.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    hideLowServiceBanner,
    showLowServiceBanner,
} from "../../actions/notifications";
import {
    selectShowLowServiceBanner,
    selectI18nStringById,
    selectTheme,
} from "../../selectors";
import { useMountEffect } from "../../../util/custom-hooks";
import { updateSearchFacet } from "../../../util/type-utils";
import Normalize from "../../components/Normalize";
import Toaster from "../../components/Toaster";
import { asyncCheckLogin } from "../../actions/system";
import { LowServiceBanner } from "../../components/LowServiceBanner";

import { get } from "@churchofjesuschrist/universal-env";
import Appearance from "@churchofjesuschrist/eden-appearance";

const { APP_URL, LOW_BANDWIDTH_THRESHOLD } = get();

const AppView = ({
    bookUri,
    children,
    fireLocationChange,
    libraryUri,
    toast,
}) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const showBanner = useSelector(selectShowLowServiceBanner);
    const I18nStringById = useSelector(selectI18nStringById);
    const theme = useSelector(selectTheme);

    const closeLowServiceBanner = () => dispatch(hideLowServiceBanner());
    const openLowServiceBanner = () => dispatch(showLowServiceBanner());

    useMountEffect(() => {
        // check for slow connection
        const perfEntries = window.performance.getEntriesByType("navigation");
        const [entry] = perfEntries;
        let domInteractive;

        const networkInformation =
            window.navigator.connection ||
            window.navigator.mozConnection ||
            window.navigator.webkitConnection;

        if (networkInformation) {
            const ect = networkInformation.effectiveType;

            if (ect !== "4g" && ect !== "3g") {
                openLowServiceBanner();
            }
        } else {
            if (!entry) {
                const timing = window.performance.timing;
                domInteractive = timing.domInteractive - timing.requestStart;
            } else {
                domInteractive = entry.domInteractive;
            }

            if (domInteractive > LOW_BANDWIDTH_THRESHOLD) {
                openLowServiceBanner();
            }
        }
    });

    useMountEffect(() => {
        const checkLogin = (event) => {
            if (event.data === "checkLogin") {
                dispatch(asyncCheckLogin(true));
            }
        };

        window.addEventListener("message", checkLogin);

        return () => window.removeEventListener("message", checkLogin);
    });

    useEffect(() => {
        updateSearchFacet(libraryUri, bookUri, I18nStringById);
    }, [libraryUri, bookUri, I18nStringById]);

    useEffect(() => {
        const redirectUri = window.location.pathname + window.location.search;

        window.platformConfig.headerConfig.returnUri = redirectUri;
        window.platformConfig.headerConfig.signInServiceUrl = `${APP_URL}/login?redirect_uri=${redirectUri}`;
        window.platformConfig.headerConfig.signOutServiceUrl = `${APP_URL}/logout?redirect_uri=${redirectUri}`;

        document.dispatchEvent(new CustomEvent("checkcontext"));
        fireLocationChange(location);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // This is overriding the viewport meta tag that header/footer adds.  We need the minimum scale to stop mobile from
    // scrolling to the closed panel.  Once they're code is in place we can remove or modify this.
    useMountEffect(() => {
        const mutationObserver = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                if (mutation.type === "childList") {
                    let viewportMetaElements = document.head.querySelectorAll(
                        'meta[name="viewport"]'
                    );

                    if (viewportMetaElements.length > 1) {
                        viewportMetaElements.forEach((meta) => {
                            meta.setAttribute(
                                "content",
                                "width=device-width, initial-scale=1, minimum-scale=1"
                            );
                        });

                        mutationObserver.disconnect();
                    }
                }
            });
        });

        mutationObserver.observe(document.head, { childList: true });

        return () => mutationObserver.disconnect();
    });

    return (
        <Appearance mode={theme}>
            <div className={styles.app}>
                <Normalize>
                    <main>{children}</main>
                </Normalize>
                <Toaster {...toast} />
                {showBanner && (
                    <LowServiceBanner onClose={closeLowServiceBanner} />
                )}
            </div>
        </Appearance>
    );
};

export default AppView;
