import "isomorphic-fetch";
import { addAnnotationAPIHeaders } from "../../util/fetch-utils";
import { get } from "@churchofjesuschrist/universal-env";
import { paramsToSearch } from "../../util/uri-utils";

const { ANNOTATIONS_API } = get();

// #region annotations
export const addAnnotation = (annotation, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations?returnResponse=false`;

    let init = {
        method: "POST",
        mode: "cors",
        compress: true,
        credentials: "include",
        body: JSON.stringify(annotation),
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const deleteAnnotation = (annotationId, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}?returnResponse=false`;

    let init = {
        method: "DELETE",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? annotationId : Promise.reject(response)
    );
};

export const getAnnotations = (
    { docId, types = [], lang, contentVersion, ...params } = {},
    headers
) => {
    const queryString = paramsToSearch({
        contentVersion,
        docId,
        type: types.join(",") || undefined,
        locale: lang,
        notesAsHtml: true,
        ...params,
    });
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations${queryString}`;

    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const addFolder = (annotationId, folderId, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/folders?returnResponse=false`;

    let init = {
        method: "PATCH",
        credentials: "include",
        compress: true,
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ folders: [folderId] }),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const removeFolder = (annotationId, folderId, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/folders/${folderId}?returnResponse=false`;

    let init = {
        method: "DELETE",
        credentials: "include",
        compress: true,
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const addTag = (annotationId, name, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/tags?returnResponse=false`;

    let init = {
        method: "PATCH",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({ tags: [name] }),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const removeTag = (annotationId, tag, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/tags/${tag}?returnResponse=false`;

    let init = {
        method: "DELETE",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateAnnotation = addAnnotation;

export const updateNote = (annotationId, note, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/note?returnResponse=false`;

    let init = {
        method: "PATCH",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(note),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateAnnotationSet = (annotationId, setId, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/set?returnResponse=false`;

    let init = {
        method: "PATCH",
        body: JSON.stringify({ annotationId, setId }),
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: { "Content-Length": 0, "Content-Type": "application/json" },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

// #endregion annotation

// #region highlights and references
export const addRef = (annotationId, ref, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/reference/${ref.pid}`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(ref),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const removeRef = (annotationId, refName, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/reference/${refName}?returnResponse=false`;

    let init = {
        method: "DELETE",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateHighlightOffsets = (annotationId, offsets = [], headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/offsets?returnResponse=false`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(offsets),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateHighlightStyle = (annotationId, style, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/style?returnResponse=false`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(style),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateRef = (annotationId, ref = {}, refName, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/reference/${refName}`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(ref),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};
// #endregion highlights and references

// #region bookmarks
export const getBookmarks = (
    { docId, lang, contentVersion, ...params } = {},
    headers
) => {
    const queryString = paramsToSearch({
        contentVersion,
        docId,
        locale: lang,
        notesAsHtml: true,
        ...params,
    });
    const url = `${ANNOTATIONS_API}/notes/api/v3/bookmarks${queryString}`;

    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const reorderBookmarks = (order, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/bookmarks/order`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(order),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateBookmark = (
    annotationId,
    { uri, pid, offset, name, docId, sort },
    headers
) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/annotations/${annotationId}/bookmark?returnResponse=false`;

    let init = {
        method: "PATCH",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, uri, pid, offset, docId, sort }),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};
// #endregion bookmarks

// #region folders
export const createFolder = (folder, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/folders`;

    let init = {
        method: "POST",
        body: JSON.stringify([folder]),
        credentials: "include",
        compress: true,
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const getFolders = ({ setId = "default", ...params }, headers) => {
    setId = setId === null ? "default" : setId;
    const queryString = paramsToSearch({
        setId,
        ...params,
    });
    const url = `${ANNOTATIONS_API}/notes/api/v3/folders${queryString}`;

    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};
// #endregion folders

// #region tags
export const createTag = (tag, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/tags`;

    let init = {
        method: "POST",
        body: JSON.stringify([tag]),
        credentials: "include",
        compress: true,
        headers: {
            "Content-Type": "application/json",
        },
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const getTags = (headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/tags`;

    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};
// #endregion tags

// #region sets
export const getSets = (headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/sets`;

    let init = {
        method: "GET",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((response) =>
        response.ok ? response.json() : Promise.reject(response)
    );
};

export const deleteSet = (setId, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/sets/${setId}`;

    let init = {
        method: "DELETE",
        mode: "cors",
        compress: true,
        credentials: "include",
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const reorderSets = (sortMap, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/sets/order`;

    let init = {
        method: "PUT",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(sortMap),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};

export const updateSet = (setId, set, headers) => {
    const url = `${ANNOTATIONS_API}/notes/api/v3/sets/${setId}`;

    let init = {
        method: "PATCH",
        mode: "cors",
        compress: true,
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(set),
        redirect: "manual",
    };

    init = addAnnotationAPIHeaders(init, headers);

    return fetch(url, init).then((res) =>
        res.ok ? res.json() : Promise.reject(res)
    );
};
// #endregion sets
