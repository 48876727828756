import { getCookies } from "../../util/cookies";
import { fetchData } from "./helpers/blt";

export const fetchContent = (params, headers) =>
    fetchData("type/content", params, headers);

export const fetchToc = (params, headers) =>
    fetchData("type/toc", params, headers);

export const fetchDynamic = (params, headers) =>
    fetchData("type/dynamic", params, headers);

export const fetchLibrary = (params, headers) =>
    fetchData("type/collection", params, headers);

export const fetchHomeData = (params, headers) =>
    fetchData("pubHub/home", params, headers);

export const fetchI18nData = (params, headers) =>
    fetchData("misc/resources", params, headers)
        .catch((err) =>
            err.status === 500
                ? { error: {}, general: {} }
                : Promise.reject(err)
        )
        .then((bundles) =>
            Object.values(bundles).reduce(
                (resources, bundle) => Object.assign(resources, bundle),
                {}
            )
        )
        .catch((err) => {
            const cookies = getCookies();
            const langCookie =
                cookies["lds-preferred-lang"] ||
                cookies["lds-preferred-lang-v2"] ||
                "eng";

            return params.lang !== langCookie
                ? fetchI18nData({ ...params, lang: langCookie }, headers)
                : Promise.reject(err);
        })
        .catch((err) =>
            params.lang !== "eng"
                ? fetchI18nData({ ...params, lang: "eng" }, headers)
                : Promise.reject(err)
        );

export const fetchUserLoggedIn = (headers) =>
    fetchData("misc/user-logged-in", {}, headers).catch(() =>
        Promise.resolve({ loggedIn: false })
    );

export const fetchAccess = (params, headers) =>
    fetchData("misc/verify-access", params, headers);

export const fetchCSSRules = (params, headers) =>
    fetchData("misc/css-rules", params, headers);

export const fetchNotifications = (params, headers) =>
    fetchData("misc/notifications", params, headers).catch(() => []);

export const fetchCacheTags = (params, headers) =>
    fetchData("cache/tags", params, headers);

export const fetchSupportedLanguages = (headers) =>
    fetchData("misc/supported-languages", {}, headers);

export const fetchTopicUri = (params, headers) =>
    fetchData("misc/topic-key", params, headers);
