import { fetchI18nData } from "../services/blt";

export const GET_I18N_RESOURCES = "GET_I18N_RESOURCES";

export const addI18nData = (resources = {}, lang = "eng") => ({
    type: GET_I18N_RESOURCES,
    payload: {
        lang,
        resources,
    },
});

/* Async Action Creators */
export const getI18nData = (params, headers) => async (dispatch, getState) => {
    let state = getState();
    let resources = state.i18n[params.lang];

    if (!resources) {
        let resources = await fetchI18nData(params, headers).catch(() => ({}));

        dispatch(addI18nData(resources, params.lang));
    }

    return resources;
};
