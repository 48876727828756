import {
    HIDE_LOW_SERVICE_BANNER,
    HIDE_TOAST,
    SET_SEEN_NOTIFICATIONS,
    SET_NOTIFICATIONS,
    SET_VISIBLE_NOTIFICATIONS,
    SHOW_LOW_SERVICE_BANNER,
    SHOW_TOAST,
} from "../actions/notifications.js";

export default (
    state = {
        notifications: undefined,
        showLowServiceBanner: false,
        toast: {
            show: false,
            type: "default",
            disableAutoHide: false,
        },
        // visibleNotifications is intentionally undefined at the start.
        // Undefined means that we haven't fetched them yet
        // Null or an empty array means that we've fetched them, but there weren't any or the user dismissed them
        visibleNotifications: undefined,
    },
    { type, payload = {} }
) => {
    switch (type) {
        case SHOW_TOAST:
            return { ...state, toast: payload };

        case HIDE_TOAST:
            return { ...state, toast: { ...state.toast, ...payload } };

        case SHOW_LOW_SERVICE_BANNER:
            return { ...state, showLowServiceBanner: true };

        case HIDE_LOW_SERVICE_BANNER:
            return { ...state, showLowServiceBanner: false };

        case SET_NOTIFICATIONS:
            return { ...state, notifications: payload };

        case SET_SEEN_NOTIFICATIONS:
            return { ...state, seenNotifications: payload };

        case SET_VISIBLE_NOTIFICATIONS:
            return { ...state, visibleNotifications: payload };

        default:
            return state;
    }
};
