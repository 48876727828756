import styleContainer from "../../containers/styles";
import { Helmet } from "react-helmet";

const StyleSheetLink = (link) => {
    // This runs both on the server and client so need to check for the correct place to check for LOCAL_IPS accordingly
    const replaceLocal =
        typeof window === "undefined"
            ? process.env.LOCAL_IPS
            : window.__APP_ENV_VARS__.LOCAL_IPS;
    // Optionally replace the CDN link with the local links for testing IPS CSS changes locally
    if (replaceLocal) {
        link.href = link.href.replaceAll(
            /https:\/\/ips-cdn(-int|-edge)?(\.aws)?\.churchofjesuschrist\.org\/styles/g,
            "http://localhost:8000/dev"
        );
    }
    return (
        <link
            {...link}
            key={link.href}
            rel="preload stylesheet"
            as="style"
            type="text/css"
            href={link.href}
        />
    );
};

const StyleManager = ({ currentLinks = [] }) => (
    <Helmet>{currentLinks.map(StyleSheetLink)}</Helmet>
);

export default styleContainer(StyleManager);
