import { Router } from "react-router-dom";
import { hydrateRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { loadableReady } from "@loadable/component";
import { createLocation } from "../util/uri-utils";
import { fireAnalyticsRouteChange, extendHistory } from "../util/history-utils";

import App from "./AppEntry";
import getStore, { history } from "./store";

const store = getStore();

// react-router does not create location.href by default.
// Replacing react-router's location with our location to make sure href is there.
history.replace(createLocation(window.location.href));

function hookHistoryBoomerang() {
    const BOOMR = window.BOOMR || {};

    if (BOOMR.version) {
        if (BOOMR.plugins && BOOMR.plugins.History) {
            BOOMR.plugins.History.hook(history, false);
        }

        return true;
    }
}

if (!hookHistoryBoomerang()) {
    if (document.addEventListener) {
        document.addEventListener("onBoomerangLoaded", hookHistoryBoomerang);
    } else if (document.attachEvent) {
        document.attachEvent("onpropertychange", function (e) {
            e = e || window.event;

            if (e && e.propertyName === "onBoomerangLoaded") {
                hookHistoryBoomerang();
            }
        });
    }
}

const BOOMR_config = (window.BOOMR_config = window.BOOMR_config || {});
BOOMR_config.autorun = false;
BOOMR_config.History = {
    enabled: true,
    auto: false,
};

loadableReady(() => {
    extendHistory(history);

    history.before(fireAnalyticsRouteChange);

    hydrateRoot(
        document.getElementById("app"),
        <Provider store={store}>
            <Router history={history}>
                <App />
            </Router>
        </Provider>
    );
});
