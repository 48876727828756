import { get } from "@churchofjesuschrist/universal-env";

const { APP_API } = get();

export const testUrl = () =>
    "http://l12869.ldschurch.org:10165/language-pages/sites/language-pages/titan/v1?uri=/eng/scriptures/bofm/1-ne/1/3&format=json";

export const getBltUrl = (api, { source = "core" } = {}) =>
    `${APP_API}/${source}/${api}`;

export default getBltUrl;
