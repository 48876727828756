import {
    selectBookmarks,
    selectHighlightAnnotationsByDocId,
} from "./annotations";
import {
    selectAssociatedMedia,
    selectCoreContentFragments,
    selectFootnotes,
    selectI18nStringById,
    selectPids,
} from "./selectors";
import { createArraySelector } from "reselect-map";
import { currentReducerKey } from "../../util/reducer-helpers";
import { createSelector } from "../../util/reselect";

export const addRelatedItemByType = (itemPid, type, pids, item) => {
    let relatedItems = pids.get(itemPid);

    return pids.set(itemPid, {
        ...relatedItems,
        [type]: [...(relatedItems[type] || []), item],
    });
};

const _selectCurrentSidePanel = (state) => state.sidePanel[currentReducerKey()];

const NO_FLOATING_PANELS = [];

const _selectFloatingPanels = (state) => {
    const currentPanel = _selectCurrentSidePanel(state);

    return currentPanel?.floatingPanels || NO_FLOATING_PANELS;
};

export const selectActivePanel = (state) => {
    const currentPanel = _selectCurrentSidePanel(state);

    return currentPanel?.activePanel;
};

export const selectSidePanelActive = (state) => {
    const activePanel = selectActivePanel(state);
    const floatingPanels = _selectFloatingPanels(state);

    return !!(activePanel || floatingPanels?.length);
};

export const selectActiveSubPanel = (state) => {
    const currentPanel = _selectCurrentSidePanel(state);

    return currentPanel?.activeSubPanel;
};

export const selectSidePanelFocusOnMount = (state) => {
    const currentPanel = _selectCurrentSidePanel(state);

    return currentPanel?.focusOnMount;
};

export const selectRelatedContentPanel = createSelector(
    [
        selectHighlightAnnotationsByDocId,
        selectFootnotes,
        selectAssociatedMedia,
        selectPids,
    ],
    (annotations, footnotes, associatedMedia, pids) => {
        if (!pids) {
            return {
                loading: true,
                relatedContentItems: [],
            };
        }

        pids = new Map(pids);

        // add associatedMedia to related content
        Object.values(associatedMedia).forEach((item) => {
            pids = addRelatedItemByType(
                item[0].pid,
                "associatedMedia",
                pids,
                item
            );
        });

        // add annotations to related content
        Object.values(annotations)
            .sort(
                (a, b) =>
                    a.highlights[0].startOffset - b.highlights[0].startOffset
            )
            .forEach((item) => {
                if (
                    item.note.title ||
                    item.note.content ||
                    item.tags.length ||
                    item.folders.length ||
                    item.refs.length
                ) {
                    pids = addRelatedItemByType(
                        item.highlights[0].pid,
                        "annotations",
                        pids,
                        item
                    );
                }
            });

        // add footnotes to related content
        Object.values(footnotes).forEach((item) => {
            pids = addRelatedItemByType(item.pid, "footnotes", pids, item);
        });

        let relatedContentItems = Array.from(pids.values()).filter(
            (item) => item.annotations || item.footnotes || item.associatedMedia
        );

        return {
            relatedContentItems,
        };
    }
);

export const selectEditorPanelOpen = createSelector(
    [_selectFloatingPanels],
    (floatingPanels) => floatingPanels.some(({ type }) => type === "editor")
);

export const selectFloatingPanels = createArraySelector(
    [
        _selectFloatingPanels,
        selectHighlightAnnotationsByDocId,
        selectFootnotes,
        selectBookmarks,
        selectCoreContentFragments,
        (state) => state.reader,
        selectI18nStringById,
        selectAssociatedMedia,
    ],
    (
        panel,
        annotations,
        footnotes,
        bookmarks,
        fragments,
        reader,
        selectI18nStringById,
        associatedMedia
    ) => {
        const buildPanel = (panel) => {
            switch (panel.type) {
                case "annotation":
                case "highlight": {
                    const annotation = annotations[panel.id];

                    return annotation
                        ? {
                              Component: "AnnotationPanel",
                              content: annotation,
                              id: panel.id,
                              key: panel.key,
                              title: selectI18nStringById(
                                  "annotationNoteTitle"
                              ),
                              testId: "notes",
                          }
                        : null;
                }

                case "associatedMedia": {
                    const media = associatedMedia[panel.id];

                    return {
                        Component: "AssociatedMediaPanel",
                        content: { associatedMedia: media },
                        id: panel.id,
                        key: panel.key,
                        title: selectI18nStringById("associatedMediaTitle"),
                        testId: "associated-content",
                    };
                }

                //eg. a scripture link in Come Follow Me that shows in the side panel
                case "crossRef": {
                    let crossRef = fragments[panel.id] || { content: [] };
                    let chapterContent = reader.contentStore[panel.id];

                    if (!chapterContent) {
                        let book = reader.bookStore[panel.id] || {};
                        let newKey = panel.id.replace(
                            /(^\/[^/]+?)\/.+$/,
                            `$1${book.firstContentUri}`
                        );

                        chapterContent = reader.contentStore[newKey];
                    } else if (chapterContent.redirect) {
                        chapterContent =
                            reader.contentStore[chapterContent.redirect];
                    }

                    if (!crossRef.content.length) {
                        crossRef = { ...crossRef, chapterContent };
                    }

                    return {
                        Component: "CrossRefPanel",
                        content: crossRef,
                        id: panel.id,
                        key: panel.key,
                        title: selectI18nStringById("referenceTitle"),
                        testId: "cross-ref",
                    };
                }

                case "footnote": {
                    const footnote = footnotes[panel.id];

                    return {
                        Component: "FootnotePanel",
                        content: footnote,
                        id: panel.id,
                        key: panel.key,
                        title: `${footnote.marker} ${
                            footnote.context || ""
                        }`.trim(),
                        testId: "footnotes",
                    };
                }

                case "bookmark": {
                    const bookmark = bookmarks[panel.id];

                    return {
                        // Bookmark can't show up in the side bar, this is for disambiguation only.
                        Component: "Bookmark",
                        content: bookmark,
                        id: panel.id,
                        key: panel.key,
                        title: bookmark.name,
                        testId: "bookmark",
                    };
                }

                case "disambiguation":
                    return {
                        Component: "DisambiguationPanel",
                        content: {
                            relatedContentItems: panel.panels.reduce(
                                (panels, panel) => {
                                    panel = buildPanel(panel);

                                    return panel ? [...panels, panel] : panels;
                                },
                                []
                            ),
                        },
                        id: panel.id,
                        key: panel.key,
                        title: selectI18nStringById("disambiguationTitle"),
                        testId: "disambiguation",
                    };

                case "editor":
                    return {
                        Component: "EditorPanel",
                        content: {
                            annotationId: panel.annotationId,
                            autoFocus: panel.autoFocus,
                        },
                        id: panel.id,
                        key: panel.key,
                        title: panel.title,
                        testId: "editor-panel",
                        ...panel,
                    };

                case "moveAnnotationToSet":
                    return {
                        Component: "MoveAnnotationToSetPanel",
                        content: {
                            annotationId: panel.annotationId,
                        },
                        id: panel.id,
                        key: panel.key,
                        title: panel.title,
                        testId: "move-annotation-to-set",
                    };

                default:
                    return panel;
            }
        };

        return buildPanel(panel);
    }
);
