import { memo, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { withRouter } from "react-router-dom";
import { useMountEffect } from "../../util/custom-hooks";
import loadable from "@loadable/component";
import {
    changeActiveAnnotation,
    createFolder,
    getBookmarks,
    getFolders,
    getHighlights,
    getSets,
    getTags,
    updateHighlightOffsets,
} from "../actions/annotations";
import { asyncCheckLogin, checkLogin } from "../actions/system";
import { getI18nData } from "../actions/i18n";
import {
    addFloatingPanel,
    clearFloatingPanels,
    getCrossRefData,
    getFootnoteData,
    removeEditorPanel,
    setActivePanel,
} from "../actions/sidePanel";
import {
    selectActiveHighlightAnnotation,
    selectActiveSetId,
    selectActivePanel,
    selectAnnotationsInitialized,
    selectAnnotationsOutOfService,
    selectAssociatedMedia,
    selectAudioSources,
    selectContent,
    selectContentTemplateType,
    selectDownloads,
    selectEditorPanelOpen,
    selectFolders,
    selectFootnotes,
    selectHighlightAnnotationsByDocId,
    selectI18nStringById,
    selectLoadedStyles,
    selectFloatingPanels,
    selectSidePanelActive,
    selectSortedBookmarks,
    selectTags,
    selectToc,
    selectLoggedIn,
    selectDocId,
} from "../selectors";
import analytics from "../../util/analytics";
import { toContentUri } from "../../util/uri-utils.js";
import { isEmpty } from "../../util/utils";
import Loading from "../components/Loading";
import mem from "mem";

const ReaderView = loadable(
    () => import(/* webpackChunkName: 'reader' */ "../templates/ReaderView"),
    { fallback: <Loading /> }
);

const mapStateToProps = (state, ownProps) => {
    const location = ownProps.location;
    const book = selectToc(state, location);

    return {
        activeAnnotation: selectActiveHighlightAnnotation(state),
        activeSetId: selectActiveSetId(state),
        activePanel: selectActivePanel(state),
        annotations: selectHighlightAnnotationsByDocId(state, location),
        annotationsInitialized: selectAnnotationsInitialized(state, location),
        annotationsOutOfService: selectAnnotationsOutOfService(state),
        associatedMedia: selectAssociatedMedia(state, location),
        audio: selectAudioSources(state, location),
        book,
        bookmarks: selectSortedBookmarks(state),
        content: selectContent(state, location),
        coreContentUri: toContentUri(location.pathname),
        downloads: selectDownloads(state, location),
        dir: state.system.dir,
        docId: selectDocId(state, location),
        editorPanelOpen: selectEditorPanelOpen(state, location),
        floatingPanels: selectFloatingPanels(state, location),
        folders: selectFolders(state),
        footnotes: selectFootnotes(state, location),
        displayFootnotes: state.localSettings.displayFootnotes,
        i18n: state.i18n,
        initialContent: state.reader.initialContent,
        lang: state.system.lang,
        location,
        loggedIn: selectLoggedIn(state),
        meta: selectContent(state, location).meta,
        selectI18nStringById: selectI18nStringById(state),
        sidePanelActive: selectSidePanelActive(state),
        loadedStyles: selectLoadedStyles(state),
        system: state.system,
        tags: selectTags(state),
        template: selectContentTemplateType(state, location),
    };
};

const mapLangToActions = mem((dispatchProps, lang) => ({
    getCrossRefData: (params) =>
        dispatchProps.getCrossRefData({ lang, ...params }),
    getFootnoteData: (id, params) =>
        dispatchProps.getFootnoteData(id, { lang, ...params }),
    getI18nData: () => dispatchProps.getI18nData({ lang }),
}));

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const lang = stateProps.lang;

    return {
        ...ownProps,
        ...stateProps,
        ...dispatchProps,
        ...mapLangToActions(dispatchProps, lang),
    };
};

const storeConnector = connect(
    mapStateToProps,
    {
        addFloatingPanel,
        asyncCheckLogin,
        changeActiveAnnotation,
        checkLogin,
        clearFloatingPanels,
        createFolder,
        getCrossRefData,
        getFootnoteData,
        getI18nData,
        removeEditorPanel,
        setActivePanel,
        updateHighlightOffsets,
    },
    mergeProps
);

const ReaderContainer = (props) => {
    const {
        activeSetId,
        annotationsOutOfService,
        asyncCheckLogin,
        book,
        content,
        checkLogin,
        crossLinkMode,
        docId,
        lang,
        loading,
        location: { pathname },
        loggedIn,
    } = props;

    useMountEffect(() => void checkLogin());

    useEffect(() => {
        !crossLinkMode &&
            content.content &&
            analytics.firePageViewEvent(asyncCheckLogin, {
                page: {
                    info: {
                        language: lang,
                    },
                    attributes: {
                        location: "main content",
                    },
                    category: {
                        primary: book.category,
                        type: "content",
                    },
                },
            });
        // TODO: Disable below is temporary to have as little functionality change as possible in this PR
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [content]);

    const dispatch = useDispatch();
    const lastBookUri = useRef();
    const lastDocId = useRef();
    const lastLang = useRef();
    const lastActiveSet = useRef();

    const attemptFetch =
        !annotationsOutOfService && loggedIn && !loading && !crossLinkMode;

    useEffect(() => {
        const activeSetIdChanged = lastActiveSet.current !== activeSetId;
        const bookUriChanged = lastBookUri.current !== book.uri;
        const docIdChanged = lastDocId.current !== docId;
        const langChanged = lastLang.current !== lang;

        if (attemptFetch) {
            lastActiveSet.current = activeSetId;
            lastBookUri.current = book.uri;
            lastDocId.current = docId;
            lastLang.current = lang;

            if (langChanged) {
                dispatch(getBookmarks());
                dispatch(getFolders());
                dispatch(getSets());
                dispatch(getTags());
                dispatch(getHighlights(pathname));
            } else if (activeSetIdChanged) {
                dispatch(getFolders());
                dispatch(getHighlights(pathname));
            } else {
                if (bookUriChanged) {
                    dispatch(getBookmarks());
                    dispatch(getFolders());
                    dispatch(getSets());
                    dispatch(getTags());
                }

                if (docIdChanged) {
                    dispatch(getHighlights(pathname));
                }
            }
        }
    }, [activeSetId, attemptFetch, book.uri, docId, lang, pathname, dispatch]);

    return <ReaderView {...props} />;
};

const ReaderContainerMemo = memo(ReaderContainer, (prevProps, nextProps) =>
    isEmpty(nextProps.content)
);

export default withRouter(storeConnector(ReaderContainerMemo));
