import styles from "./eden-banner.css";
import classes from "classnames";
import {
    Cancel as EdenCancel,
    Error as EdenError,
    Help as EdenHelp,
    Info as EdenInfo,
    Success as EdenSuccess,
    Warn as EdenWarn,
} from "@churchofjesuschrist/eden-banner";

export const Cancel = ({ className, ...props }) => (
    <EdenCancel className={classes(styles.banner, className)} {...props} />
);

export const Error = ({ className, ...props }) => (
    <EdenError className={classes(styles.banner, className)} {...props} />
);

export const Help = ({ className, ...props }) => (
    <EdenHelp
        className={classes(styles.banner, styles.basic, className)}
        {...props}
    />
);

export const Info = ({ className, ...props }) => (
    <EdenInfo
        className={classes(styles.banner, styles.basic, className)}
        {...props}
    />
);

export const Success = ({ className, ...props }) => (
    <EdenSuccess className={classes(styles.banner, className)} {...props} />
);

export const Warn = ({ className, ...props }) => (
    <EdenWarn className={classes(styles.banner, className)} {...props} />
);

export {
    Cancel as CancelBanner,
    Error as ErrorBanner,
    Help as HelpBanner,
    Info as InfoBanner,
    Success as SuccessBanner,
    Warn as WarnBanner,
};
