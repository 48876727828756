import packageJson from "../../package.json";

export const addHeaders = (init, headers) =>
    headers ? { ...init, headers: { ...init.headers, ...headers } } : init;

export const addAnnotationAPIHeaders = (init, headers) => {
    headers = {
        ...headers,
        "client-app-version": `GLO-${packageJson.version}`,
    };

    return addHeaders(init, headers);
};
