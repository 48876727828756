import { cleanUri, removeAppBase, searchToParams } from "./uri-utils";
import isBrowser from "is-in-browser";

export const addItemToSubState = (subStateKey, state = {}, key, item) => ({
    ...state,
    [subStateKey]: {
        ...state[subStateKey],
        [key]: item,
    },
});

export const removeItemFromSubState = (subStateKey, state = {}, key) => {
    const {
        [key]: removedItem, // eslint-disable-line no-unused-vars
        ...remainingItems
    } = state[subStateKey];

    return {
        ...state,
        [subStateKey]: remainingItems,
    };
};

export const spreadItemsToSubState = (subStateKey, state = {}, items = {}) => ({
    ...state,
    [subStateKey]: {
        ...state[subStateKey],
        ...items,
    },
});

export const removeItemsFromSubState = (subStateKey, state = {}, keys = []) => {
    let subState = { ...state[subStateKey] };

    keys.forEach((key) => delete subState[key]);

    return {
        ...state,
        [subStateKey]: subState,
    };
};

export const concatItemsToSubState = (subStateKey, state, key, ...items) => {
    const subState = state[subStateKey];

    return {
        ...state,
        [subStateKey]: {
            ...subState,
            [key]: subState[key].concat(...items),
        },
    };
};

export const filterItemFromSubState = (
    subStateKey,
    state,
    key,
    itemToFilter
) => {
    const subState = state[subStateKey];

    return {
        ...state,
        [subStateKey]: {
            ...subState,
            [key]: subState[key].filter((item) => item !== itemToFilter),
        },
    };
};

export const mapToSubState = (subStateKey, key) => (state, item) =>
    addItemToSubState(subStateKey, state, item[key], item);

export const toReducerKey = (uri, lang = "eng") =>
    `/${lang}${removeAppBase(cleanUri(uri))}`;

export const deconstructReducerKey = (reducerKey) => {
    let [key, lang, uri] = reducerKey.match(/^\/(.+?)(\/.*$)/);

    return { key, lang, uri };
};

export const currentReducerKey = (location) => {
    location =
        location ||
        (isBrowser ? window.location : { pathname: "", search: "" });

    let uri = location.pathname;
    let query = searchToParams(location.search);
    let lang = query.lang;

    return toReducerKey(uri, lang);
};

export const toStyleSheetKey = (href = "") => {
    const hrefSplit = href.split("/");

    return (
        "/" +
            hrefSplit[hrefSplit.length - 1]
                .split(/style_|\./)
                .slice(-2, -1)[0] || ""
    ).replace("_scoped", "");
};

export const toHighlightKey = (annotationId, pid) => `${pid}/${annotationId}`;

export const buildHighlightKeys = (annotationId, highlights) =>
    highlights.map(({ pid }) => toHighlightKey(annotationId, pid));

export const determineIconType = ({
    note: { title, content },
    folders = [],
    tags = [],
    refs = [],
}) =>
    ((title || content) && "note") ||
    (refs.length && "link") ||
    (tags.length && "tag") ||
    (folders.length && "notebook") ||
    undefined;

export const reduceEntries = (acc, [key, value]) =>
    Object.assign(acc, { [key]: value });
