import {
    SET_FONT_SANS_SERIF,
    SET_FONT_SIZE,
    SET_DISPLAY_FOOTNOTES,
    SET_PRINT_FOOTNOTES,
    SET_PRINT_HIGHLIGHTS,
    SET_SHOW_ARCHIVED_CONTENT,
    SET_IMAGE_PREFERENCES,
    SET_VIDEO_PREFERENCES,
    SET_HIGHLIGHT_STYLE,
    SET_APP_THEME,
} from "../actions/localSettings";

export default (
    state = {
        defaultStyle: { color: "yellow" },
        displayFootnotes: true,
        printFootnotes: true,
        printHighlights: true,
        showArchivedContent: false,
        theme: "os",
    },
    { type, payload = {} }
) => {
    switch (type) {
        case SET_FONT_SANS_SERIF:
            return {
                ...state,
                fontSansSerif: payload,
            };

        case SET_FONT_SIZE:
            return {
                ...state,
                fontSize: payload,
            };

        case SET_DISPLAY_FOOTNOTES:
            return {
                ...state,
                displayFootnotes: payload,
            };

        case SET_PRINT_FOOTNOTES:
            return {
                ...state,
                printFootnotes: payload,
            };

        case SET_PRINT_HIGHLIGHTS:
            return {
                ...state,
                printHighlights: payload,
            };

        case SET_SHOW_ARCHIVED_CONTENT:
            return {
                ...state,
                showArchivedContent: payload,
            };

        case SET_IMAGE_PREFERENCES:
            return {
                ...state,
                disableImages: payload,
            };

        case SET_VIDEO_PREFERENCES:
            return {
                ...state,
                disableVideos: payload,
            };

        case SET_HIGHLIGHT_STYLE:
            return {
                ...state,
                defaultStyle: {
                    color: "yellow",
                    ...payload,
                },
            };
        case SET_APP_THEME:
            return {
                ...state,
                theme: payload,
            };

        default:
            return { ...state };
    }
};
