/* eslint-disable react/display-name */
import styles from "./IconButton.css";
import classes from "classnames";
import { IconButton as ThemedIconButton } from "../../theming/components/eden-buttons";

const IconButton = ({
    className,
    Icon,
    innerRef,
    size = "small",
    onClick = () => {},
    optionalClickParam,
    title,
    hiddenTitleText,
    ...props
}) => (
    <ThemedIconButton
        className={classes(styles.iconButton, className)}
        ref={innerRef}
        size={size}
        title={title}
        aria-label={hiddenTitleText}
        onClick={(event) => onClick(event, optionalClickParam)}
        {...props}
    >
        <Icon />
    </ThemedIconButton>
);

export const iconButtonFactory = (Icon) => (props) =>
    <IconButton Icon={Icon} {...props} />;

export default IconButton;
