import { get } from "@churchofjesuschrist/universal-env";
import isBrowser from "is-in-browser";
import { jwtDecode } from "jwt-decode";
import { reduceEntries } from "./reducer-helpers";

const { OAUTH_COOKIE_NAME } = get();

export const getCookies = () => {
    if (!isBrowser) {
        return {};
    }

    return document.cookie
        .split("; ")
        .map((cookie) => cookie.split("="))
        .reduce(reduceEntries, {});
};

export const eraseCookie = (name, uri) => {
    const cookie = `${name}=;Path=${uri};Domain=.churchofjesuschrist.org;Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    document.cookie = cookie;
};

export const getChurchId = () => {
    const cookies = getCookies();
    const jwt = cookies[OAUTH_COOKIE_NAME];

    if (!jwt) return null;

    const userData = jwtDecode(jwt);

    return userData.churchAccountID;
};
