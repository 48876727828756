import styles from "./Loading.css";
import classes from "classnames";

const Loading = ({ className }) => (
    <div className={classes(className, styles.loading)}>
        <div className={styles.loader}>
            <div className={styles.outerLoader}></div>
        </div>
    </div>
);

export default Loading;
