import styles from "./SignInPrompt.css";
import { Profile as ProfileIcon } from "@churchofjesuschrist/eden-icons";
import { get } from "@churchofjesuschrist/universal-env";
import classes from "classnames";
import isBrowser from "is-in-browser";
import { useSelector } from "react-redux";
import { iconButtonFactory } from "../../components/IconButton";
import { selectAnnotationsOutOfService } from "../../selectors";
import Row from "@churchofjesuschrist/eden-row";
import Stack from "@churchofjesuschrist/eden-stack";
import { Error } from "../../theming/components/eden-alert";
import { PrimaryButton } from "../../theming/components/eden-buttons";
import { Text4, Text6 } from "../../theming/components/eden-text";
import { useSelectI18nStringById } from "../../../util/custom-hooks";

const { APP_URL, REGISTER_URL } = get();

const ProfileButton = iconButtonFactory(ProfileIcon);

ProfileButton.displayName = "ProfileButton";

const buildSignInUrl = () =>
    `${APP_URL}/login?redirect_uri=${encodeURIComponent(
        window.location.pathname + window.location.search
    )}`;

const SignInButton = ({ small }) => {
    let selectI18nStringById = useSelectI18nStringById();

    return (
        <PrimaryButton href={buildSignInUrl()} small={small}>
            {selectI18nStringById("signInButtonLabel")}
        </PrimaryButton>
    );
};

const SignInPrompt = ({ className, signInDescription }) => {
    let selectI18nStringById = useSelectI18nStringById();
    let annotationsOutOfService = useSelector(selectAnnotationsOutOfService);

    if (!isBrowser) return null;

    const annotationsOutOfServiceText = selectI18nStringById(
        "annotationsOutOfServiceText"
    );

    let signInMessage = annotationsOutOfService
        ? annotationsOutOfServiceText
        : signInDescription;

    return (
        <div className={classes(styles.login, className)}>
            <div
                className={classes(
                    annotationsOutOfService && styles.outOfService
                )}
            >
                {signInMessage}
            </div>
            <SignInButton />
            <Text6>
                <a
                    className={styles.signInHelpLink}
                    href={`${REGISTER_URL}?target=${window.location.href}`}
                >
                    {selectI18nStringById("signInHelpLink")}
                </a>
            </Text6>
        </div>
    );
};

const SignInLink = ({ classNames, menuItemLabelClassName }) => {
    let selectI18nStringById = useSelectI18nStringById();

    if (!isBrowser) return null;

    return (
        <a
            className={classes(styles.signInLink, classNames)}
            href={buildSignInUrl()}
        >
            <ProfileButton
                hiddenTitleText={selectI18nStringById("signInButtonLabel")}
            />
            <span className={menuItemLabelClassName}>
                {selectI18nStringById("signInButtonLabel")}
            </span>
        </a>
    );
};

// how do we account for any hashes in the url?
const LoggedOffAlert = ({ handleClose, className }) => {
    let selectI18nStringById = useSelectI18nStringById();

    const closeAlert = () => {
        // blur the close button that way we can hide the alert without the user having to move focus out of the alert.
        document.activeElement.blur();
        handleClose();
    };

    return (
        <Error className={className} onClose={closeAlert}>
            <Stack>
                <Text4>
                    {selectI18nStringById("logginToSaveAnnotationToastMessage")}
                </Text4>
                <Row>
                    <SignInButton />
                </Row>
            </Stack>
        </Error>
    );
};

export {
    buildSignInUrl,
    LoggedOffAlert,
    ProfileButton,
    SignInLink,
    SignInButton,
    SignInPrompt,
};
export default SignInPrompt;
