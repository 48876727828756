import { fetchUserLoggedIn, fetchCSSRules } from "../services/blt";
import { dirFromLang } from "../../util/lang-utils";
import { isPromise } from "../../util/utils";
import { _selectLoggedIn } from "../selectors";
import { exchangeRefreshToken } from "../services/oauth";
import { getCookies } from "../../util/cookies";
import { get } from "@churchofjesuschrist/universal-env";

const { NODE_ENV } = get();

export const LOCATION_CHANGE = "LOCATION_CHANGE";
export const RECEIVE_HTTP_RESPONSE_CODE = "RECEIVE_HTTP_RESPONSE_CODE";
export const RECEIVE_HTTP_RESPONSE_CODE_RESET =
    "RECEIVE_HTTP_RESPONSE_CODE_RESET";

export const RECEIVE_APPLICATION_ERROR_RESET =
    "RECEIVE_APPLICATION_ERROR_RESET";
export const RECEIVE_APPLICATION_ERROR = "RECEIVE_APPLICATION_ERROR";
export const RECEIVE_APPLICATION_ERROR_REMOVAL =
    "RECEIVE_APPLICATION_ERROR_REMOVAL";

export const RECEIVE_LOADING_START = "RECEIVE_LOADING_START";
export const RECEIVE_LOADING_END = "RECEIVE_LOADING_END";

export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_LOGGED_IN = "SET_LOGGED_IN";

export const SET_ANNOTATIONS_OUT_OF_SERVICE = "SET_ANNOTATIONS_OUT_OF_SERVICE";

export const SET_CSS_RULES = "SET_CSS_RULES";

export const setHttpResponseCode = (code) => ({
    type: RECEIVE_HTTP_RESPONSE_CODE,
    payload: code,
});

export const resetHttpResponseCode = () => ({
    type: RECEIVE_HTTP_RESPONSE_CODE_RESET,
});

export const resetApplicationErrors = () => ({
    type: RECEIVE_APPLICATION_ERROR_RESET,
});

export const addApplicationError = ({ id, title, description, date }) => ({
    type: RECEIVE_APPLICATION_ERROR,
    payload: { id, title, description, date },
});

export const removeApplicationError = ({ id }) => ({
    type: RECEIVE_APPLICATION_ERROR_REMOVAL,
    payload: { id },
});

export const startLoading = () => ({
    type: RECEIVE_LOADING_START,
});

export const endLoading = () => ({
    type: RECEIVE_LOADING_END,
});

export const setLang = (lang) => ({
    type: SET_LANGUAGE,
    payload: {
        dir: dirFromLang(lang),
        lang,
    },
});

export const fireLocationChange = (location) => ({
    type: LOCATION_CHANGE,
    payload: location,
});

export const setLoggedIn = (loggedIn = false) => ({
    type: SET_LOGGED_IN,
    payload: loggedIn,
});

export const setAnnotationsOutOfService = (
    annotationsOutOfService = false
) => ({
    type: SET_ANNOTATIONS_OUT_OF_SERVICE,
    payload: annotationsOutOfService,
});

export const setCSSRules = (cssRules) => ({
    type: SET_CSS_RULES,
    payload: cssRules,
});

const resolveAsyncLogin = async (loggedInPromise) =>
    (await loggedInPromise).loggedIn;

const getLoggedIn = () => async (dispatch) => {
    let loggedInDataPromise = fetchUserLoggedIn();
    let loggedInPromise = resolveAsyncLogin(loggedInDataPromise);

    // saving the wrapped promise so that future login checks can await it without making another network call
    dispatch(setLoggedIn(loggedInPromise));

    try {
        let { annotationsOutOfService, loggedIn } = await loggedInDataPromise;

        dispatch(setAnnotationsOutOfService(annotationsOutOfService));
        dispatch(setLoggedIn(loggedIn));
    } catch (err) {
        dispatch(setAnnotationsOutOfService(false));
        dispatch(setLoggedIn(false));
    }

    return loggedInPromise;
};

export const checkLogin = () => (dispatch, getState) => {
    const state = getState();
    let loggedIn = _selectLoggedIn(state);

    if (isPromise(loggedIn)) {
        loggedIn = false;
    } else if (loggedIn === null) {
        dispatch(getLoggedIn());
    }

    return loggedIn || false;
};

export const asyncCheckLogin =
    (forceLoginCheck) => async (dispatch, getState) => {
        const state = getState();
        let loggedInPromise = _selectLoggedIn(state);

        if (loggedInPromise === null || forceLoginCheck) {
            loggedInPromise = dispatch(getLoggedIn());
        }

        // Refresh the session with a refresh token if it exists
        const refreshTokenCookieName = `oauth_refresh_token${
            NODE_ENV !== "production" ? "-int" : ""
        }`;
        const refreshTokenExists = getCookies()[refreshTokenCookieName];

        if (loggedInPromise === false && refreshTokenExists) {
            const refreshResponse = await exchangeRefreshToken();

            if (refreshResponse.success) {
                loggedInPromise = dispatch(getLoggedIn());
            }
        }

        return loggedInPromise;
    };

export const getCSSRules = (params, headers) => async (dispatch) => {
    const cssRules = await fetchCSSRules(params, headers);

    dispatch(setCSSRules(cssRules.rules));

    return cssRules;
};
