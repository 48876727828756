import classes from "classnames";
import {
    Text1 as EdenText1,
    Text2 as EdenText2,
    Text3 as EdenText3,
    Text4 as EdenText4,
    Text5 as EdenText5,
    Text6 as EdenText6,
} from "@churchofjesuschrist/eden-text";

export const Text1 = ({ children = "", className, ...props }) => (
    <EdenText1 className={classes(className)} {...props}>
        {children}
    </EdenText1>
);

export const Text2 = ({ children = "", className, ...props }) => (
    <EdenText2 className={classes(className)} {...props}>
        {children}
    </EdenText2>
);

export const Text3 = ({ children = "", className, ...props }) => (
    <EdenText3 className={classes(className)} {...props}>
        {children}
    </EdenText3>
);

export const Text4 = ({ children = "", className, ...props }) => (
    <EdenText4 className={classes(className)} {...props}>
        {children}
    </EdenText4>
);

export const Text5 = ({ children = "", className, ...props }) => (
    <EdenText5 className={classes(className)} {...props}>
        {children}
    </EdenText5>
);

export const Text6 = ({ children = "", className, ...props }) => (
    <EdenText6 className={classes(className)} {...props}>
        {children}
    </EdenText6>
);
