import {
    LOCATION_CHANGE,
    RECEIVE_HTTP_RESPONSE_CODE,
    RECEIVE_HTTP_RESPONSE_CODE_RESET,
    SET_ANNOTATIONS_OUT_OF_SERVICE,
    SET_CSS_RULES,
    SET_LANGUAGE,
    SET_LOGGED_IN,
} from "../actions/system.js";
import { dirFromLang } from "../../util/lang-utils";
import { searchToParams } from "../../util/uri-utils";

export default (
    state = {
        cssRules: {},
        lang: "eng",
        loggedIn: false,
        status: 200,
    },
    { type, payload = {} }
) => {
    switch (type) {
        case RECEIVE_HTTP_RESPONSE_CODE:
            return { ...state, status: payload };

        case RECEIVE_HTTP_RESPONSE_CODE_RESET:
            return { ...state, status: 200 };

        case SET_LANGUAGE:
            return payload.lang !== state.lang
                ? { ...state, ...payload }
                : state;

        case LOCATION_CHANGE: {
            let query = searchToParams(payload.search);

            return {
                ...state,
                lang: query.lang,
                dir: dirFromLang(query.lang),
            };
        }

        case SET_LOGGED_IN:
            if (payload === state.loggedIn) return state;
            else return { ...state, loggedIn: payload };

        case SET_ANNOTATIONS_OUT_OF_SERVICE:
            return { ...state, annotationsOutOfService: payload };

        case SET_CSS_RULES:
            return { ...state, cssRules: payload };

        default:
            return state;
    }
};
