import { ADD_CORE_CONTENT_FRAGMENTS } from "../actions/coreContent.js";
import { cleanUri, prependAppBase } from "../../util/uri-utils";

export default (state = {}, { type, payload = {} }) => {
    switch (type) {
        case ADD_CORE_CONTENT_FRAGMENTS:
            return {
                ...state,
                ...Object.values(payload).reduce((newFragments, fragment) => {
                    return {
                        ...newFragments,
                        [fragment.uri]: {
                            content: [],
                            ...fragment,
                            referenceURI: cleanUri(
                                prependAppBase(fragment.referenceURI)
                            ),
                            uri: cleanUri(prependAppBase(fragment.uri)),
                        },
                    };
                }, {}),
            };

        default:
            return state;
    }
};
