import { fetchSupportedLanguages } from "../app/services/blt";

export const dirFromLang = (lang) =>
    ["urd", "ara", "pes"].includes(lang) ? "rtl" : "ltr";

export const checkLang = async (lang) => {
    let supportedLangs = await fetchSupportedLanguages().catch(() => ["eng"]);

    return supportedLangs.includes(lang);
};
