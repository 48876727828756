import {
    addToSessionStorage,
    deleteFromSessionStorage,
    getFromSessionStorage,
} from "../../util/store-on-client";
import { asyncCheckLogin } from "./system";
import { showToast } from "./notifications";
import { updateAnnotation } from "./annotations";
import {
    selectHighlightAnnotationById,
    selectBookmarkAnnotationById,
} from "../selectors/annotations";
import { exchangeRefreshToken } from "../services/oauth";
import { updateAnnotation as updateAnnotationService } from "../services/annotations";
import { annotationToApi } from "../transforms/annotations";

let refreshPromise = null;

export const handleSaveError =
    (error, annotationId) => async (dispatch, getState) => {
        const state = getState();
        // selectBookmarkAnnotationById returns undefined when the annotation is a bookmark.
        // TODO in CSGLO-1700 - figure out how we can select the bookmark annotation.
        let annotation =
            selectHighlightAnnotationById(state, annotationId) ||
            selectBookmarkAnnotationById(state, annotationId);

        if (!refreshPromise) {
            refreshPromise = exchangeRefreshToken();
        }

        const refreshResponse = await refreshPromise;

        refreshPromise = null;

        if (refreshResponse.success) {
            try {
                // TODO in CSGLO-1700 - Does annotationToApi work for bookmark annotations?
                const transformedAnnotation = annotationToApi(annotation);
                await updateAnnotationService(transformedAnnotation);
            } catch (err) {
                dispatch(backupAnnotation(annotation));

                throw err;
            }
        } else {
            dispatch(backupAnnotation(annotation));

            throw error;
        }
    };

export const handleGetError = (error, action) => async (dispatch) => {
    if (!refreshPromise) {
        refreshPromise = exchangeRefreshToken();
    }

    const refreshResponse = await refreshPromise;

    refreshPromise = null;

    if (refreshResponse.success) {
        dispatch(action);
    } else {
        throw error;
    }
};

const backupAnnotation = (annotation) => async (dispatch) => {
    const loggedIn = await dispatch(asyncCheckLogin(true));
    const storageKey = "unsavedAnnotations";

    let unsavedAnnotations = getFromSessionStorage(storageKey);
    unsavedAnnotations = JSON.parse(unsavedAnnotations) || {};

    // if it exists or is new this will will overwrite it or add it
    unsavedAnnotations[annotation?.id] = annotation;

    addToSessionStorage(storageKey, unsavedAnnotations);

    if (loggedIn) {
        dispatch(showToast({ type: "failed-to-save" }));
    } else {
        dispatch(showToast({ type: "logged-off", disableAutoHide: true }));
    }
};

const cleanUpUnsavedAnnotations = () => {
    const storageKey = "unsavedAnnotations";

    return deleteFromSessionStorage(storageKey);
};

const saveBackedUpAnnotation = (annotation) => async (dispatch) => {
    try {
        // TODO in CSGLO-1700 - updateAnnotation SHOULD put the annotation in the store, it currently doesn't.
        await dispatch(updateAnnotation(annotation));
        dispatch(showToast({ type: "saved" }));

        return Promise.resolve();
    } catch (err) {
        dispatch(showToast({ type: "failed-to-save" }));

        return Promise.reject("failed-to-save");
    }
};

const checkForUnsavedAnnotations =
    (annotation, annotationId) => async (dispatch) => {
        const loggedIn = await dispatch(asyncCheckLogin(true));
        const storageKey = "unsavedAnnotations";
        const id = annotation?.id || annotationId;

        let unsavedAnnotations = getFromSessionStorage(storageKey);
        unsavedAnnotations = JSON.parse(unsavedAnnotations);

        if (
            loggedIn &&
            unsavedAnnotations &&
            Object.keys(unsavedAnnotations).length
        ) {
            // delete the provided ID from the object, just in case it saved something newer than was in the object
            delete unsavedAnnotations[id];

            // loop through each unsavedAnnotation and save it, then remove the current node from the object
            for (let key in unsavedAnnotations) {
                // save the current annotation then delete the current annotation from the object... catch if it fails.
                dispatch(saveBackedUpAnnotation(unsavedAnnotations[key]))
                    .then(delete unsavedAnnotations[key])
                    .catch((error) =>
                        console.error(
                            `Error - failed to save backed up annotation ${key}`,
                            error
                        )
                    );
            }

            // delete 'unsavedAnnotations' from session storage once all unsaved annotations have been deleted/saved
            Object.keys(unsavedAnnotations).length === 0
                ? dispatch(cleanUpUnsavedAnnotations())
                : addToSessionStorage(storageKey, unsavedAnnotations);
        } else {
            return;
        }
    };

export default handleSaveError;
export {
    saveBackedUpAnnotation,
    cleanUpUnsavedAnnotations,
    checkForUnsavedAnnotations,
};
