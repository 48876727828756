import { getScrollingElement, getScrollTop } from "./scroll-utils";

const createBeforeListener = (history) => {
    history._beforeThunks = [];

    history.before = (thunk) => {
        history._beforeThunks.push(thunk);
    };

    history.block((location, action) => {
        history._beforeThunks.forEach((thunk) => thunk(location, action));
    });
};

export const preserveScrollHistory = (history) => {
    const saveScrollPosition = () => {
        let scrollTop = getScrollTop();
        sessionStorage.setItem(history.location.key, scrollTop);
    };

    const restoreScrollPosition = () => {
        let scrollingElement = getScrollingElement();
        let scrollTop = sessionStorage.getItem(history.location.key);

        if (scrollingElement && scrollTop != null) {
            scrollingElement.scrollTop = scrollTop;
        }
    };

    history.before(saveScrollPosition);
    history.listen(restoreScrollPosition);
};

export const fireAnalyticsRouteChange = (location) => {
    let pathnameChanged = location.pathname !== window.location.pathname;
    let searchChanged = location.search !== window.location.search;

    if (pathnameChanged || searchChanged) {
        document.dispatchEvent(new CustomEvent("analyticsroutechange"));
    }
};

const historyModifiers = [createBeforeListener, preserveScrollHistory];

export const extendHistory = (history) => {
    historyModifiers.forEach((modifier) => modifier(history));
};
