import { fetchHomeData } from "../services/blt";
import { setHttpResponseCode } from "./system";

export const GET_HOME_DATA = "GET_HOME_DATA";

export const addHomeData = (homeData) => ({
    type: GET_HOME_DATA,
    payload: homeData,
});

/* Async Action Creators */
export const getHomeData = (params, headers) => (dispatch) =>
    fetchHomeData(params, headers)
        .then((homeData) => {
            dispatch(setHttpResponseCode(200));
            Promise.resolve(dispatch(addHomeData(homeData)));
        })
        .catch((err) => {
            dispatch(setHttpResponseCode(err.status));
            Promise.resolve(dispatch(addHomeData(err)));
        });
